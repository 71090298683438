.general-page {
  display: flex;
  padding-right: 4px;
  align-items: center;
  align-self: stretch;
  position: relative;
}

.general-page-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  /* overflow-y: auto; */
}

.gp-first-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.gp-first-section-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.first-section-text-container {
  display: flex;
  height: 30px;
  align-items: center;
  align-self: stretch;
}

.first-section-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.asset-info-popup-highchart-container-buttons {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  width: 100%;
  flex-wrap: wrap;
}

.asset-info-popup-highchart-container-button-active {
  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background: var(--secondary-electric-violet-700, #4e2bd7);
}

.asset-info-popup-highchart-container-button-text-active {
  color: var(--Neutral-White-100, #fff);
  text-align: center;

  /* Paragraph 04/P3 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 0.875rem */
  width: 20px;
}

.asset-info-popup-highchart-container-button {
  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-50, #f7f7f8);
  background: var(--Neutral-50, #f7f7f8);
}

.asset-info-popup-highchart-container-button-text {
  color: var(--Neutral-400, #8f91a1);
  text-align: center;

  /* Paragraph 04/P3 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 0.875rem */
  width: 20px;
}

.first-section-body {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.gp-separator {
  display: flex;
  width: 291px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-200, #d8d9df);
  cursor: pointer;
  user-select: none;
}

.gp-separator:hover {
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-200, #d8d9df);
  background: var(--Neutral-50, #f7f7f8);
}

.gp-separator-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.gp-separator-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.general-page-main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}

.general-page-main-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.gp-main-content-header-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.gp-main-content-header-button {
  display: flex;
  width: 100px;
  padding: 6px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid var(--secondary-electric-violet-700, #4e2bd7);
  cursor: pointer;
  user-select: none;
}

.gp-main-content-header-button-borderless {
  display: flex;
  width: 100px;
  padding: 6px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  user-select: none;
}

.gp-main-content-header-button-text {
  color: var(--secondary-electric-violet-600, #603ff4);
  text-align: center;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 1.09375rem */
}

.gp-dot-slider-container-outter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.general-page-main-content-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  overflow: hidden;
}

.gp-main-content-footer-buttons {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  align-self: stretch;
}

.gp-main-content-footer-buttons-container {
  display: flex;
  width: 1022px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.asset-info-popup-trading-first-header-dropdown {
  display: flex;
  flex-direction: column;
  padding: 6px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: var(--secondary-electric-violet-900, #362689);
  z-index: 2;
  height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}

.asset-info-popup-trading-first-header-dropdown-cont {
  display: flex;
  flex-direction: column;
  padding: 4px;
  gap: 4px;
  height: 150px;
  z-index: 2;
}

.asset-info-popup-trading-first-header-dropdown-content {
  display: flex;
  flex-direction: row;
  padding: 4px;
  gap: 6px;
  cursor: pointer;
  width: 100%;
}

.asset-info-popup-trading-first-header-dropdown-content:hover {
  background-color: #21174f;
  border-radius: 4px;
}

.asset-info-popup-trading-first-header-dropdown-content-img {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  overflow: hidden;
}

.gp-main-content-footer-buttons-group {
  display: flex;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  overflow-x: auto;
  scrollbar-width: none;
}

.gp-main-content-footer-button {
  display: flex;
  height: 32px;
  padding: var(--space-8px, 8px) 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--secondary-electric-violet-700, #4e2bd7);
  cursor: pointer;
  user-select: none;
  min-width: fit-content;
}

.gp-main-content-footer-button-active {
  display: flex;
  height: 32px;
  padding: var(--space-8px, 8px) 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--secondary-electric-violet-700, #4e2bd7);
  cursor: pointer;
  user-select: none;
  min-width: fit-content;
}

.gp-main-content-footer-button-text {
  color: var(--secondary-electric-violet-700, #4e2bd7);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.gp-main-content-footer-button-text-active {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.gp-main-content-footer-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.gp-main-content-footer-content {
  display: flex;
  width: 100%;
  height: 70px;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--common-white_states-main, #fff);
  cursor: pointer;
}

.gp-main-content-footer-content-first-section {
  display: flex;
  align-items: center;
  gap: 50px;
}

.gp-main-content-footer-content-title {
  display: flex;
  width: 100px;
  align-items: center;
  gap: 4px;
}

.gp-main-content-footer-content-title-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.gp-main-content-footer-content-description {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  width: 150px;
}

.gp-main-content-footer-content-symbol {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  width: 100px;
}
.gp-main-content-footer-content-second-section {
  display: flex;
  align-items: center;
  gap: 100px;
}

.gp-main-content-footer-content-container-loading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.gp-main-content-footer-content-container-loading-big {
  height: 149px;
  align-self: stretch;
  border-radius: 8px;
  background: linear-gradient(90deg, #fafafb 0%, #fefefe 100%);
}

.gp-main-content-footer-content-container-loading-medium {
  height: 80px;
  align-self: stretch;
  border-radius: 8px;
  background: linear-gradient(90deg, #fafafb 0%, #fefefe 100%);
}

.gp-main-content-footer-content-container-loading-small {
  height: 35px;
  align-self: stretch;
  border-radius: 8px;
  background: linear-gradient(90deg, #fafafb 0%, #fefefe 100%);
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.gp-main-content-second-section-image {
  width: 120px;
  height: 100px;
  /* overflow: hidden; */
}

.gp-main-content-second-section-text-container {
  display: flex;
  align-items: center;
  gap: 40px;
}

.gp-main-content-price-percentage {
  color: var(--Neutral-400, #38602b);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  min-width: 100px;
}

.gp-main-content-price-container {
  display: flex;
  align-items: center;
}

.gp-main-content-price-up {
  color: var(--Success-700, #38602b);
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  width: 88px;
  text-align: right;
}

.gp-main-content-price-down {
  color: var(--Error-700, #bc1919);
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  width: 88px;
  text-align: right;
}

.gp-main-content-price-icon {
  /* width: 24px;
height: 24px; */
}

.general-page-third-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.general-page-third-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.gp-third-content-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.general-page-third-content-bottom {
  display: flex;
  padding-top: 10px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  overflow: auto;
}

.gp-third-content-bottom-content-container {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: none;
}

.gp-third-content-bottom-content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--common-white_states-main, #fff);
  position: relative;
  width: 220px;
  cursor: pointer;
  user-select: none;
}

.gp-third-content-bottom-first-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
}

.gp-third-content-bottom-first-section-text-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.gp-third-content-bottom-second-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
}

.gp-third-content-bottom-second-section-header-text {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.gp-third-content-bottom-second-section-footer-text {
  color: var(--Neutral-900, #383842);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.gp-third-content-line-separator {
  width: 100%;
  height: 1px;
  background: #eeeef0;
}

.offer-security-popup-body-expand-container {
  display: flex;
  width: 100%;
  padding: 16px 10px;
  max-height: 172px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  left: 0;
  top: 53px;
  z-index: 2;
  overflow-y: auto;
}

.offer-security-popup-body-first-expand-cont {
  width: 100%;
  gap: 12px;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-right: 10px;
}

.offer-security-popup-body-first-expand-cont:hover {
  background: var(--Neutral-50, #f7f7f8);
  border-radius: 8px;
}

.offer-security-popup-body-first-expand-text-mult {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.error-404-page {
  display: flex;
  width: 313px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
}

.error-404-page-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.error-404-page-title {
  align-self: stretch;
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Heading/H1 */
  font-family: Manrope;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%; /* 2.625rem */
  letter-spacing: 0.035rem;
}

.error-404-body-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.error-404-body-title {
  color: var(--Neutral-900, #383842);
  text-align: center;

  /* Heading/H4 */
  font-family: Manrope;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.5rem */
}

.error-404-body-label {
  color: var(--Neutral-500, #717286);
  text-align: center;

  /* Paragraph 01/P1 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
}

.offer-security-expand-input {
  border: none;
  background: transparent;
  width: 90%;
  height: 47px;
  color: var(--Neutral-900, #383842);
  padding-left: 10px;
  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.offer-security-expand-input::placeholder {
  color: var(--Neutral-900, #3d3d43);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.offer-security-popup-body-first-expand-text {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.offer-security-popup-input {
  display: flex;
  height: 48px;
  padding: 12px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-200, #d8d9df);
  background: var(--Neutral-50, #f7f7f8);
  color: var(--Neutral-900, #383842);
  width: 100%;
  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.offer-security-popup-input::placeholder {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.gp-third-content-bottom-content-loading {
  background: linear-gradient(90deg, #fafafb 0%, #fefefe 100%);
  height: 134px;
  width: 220px;
}

.gp-third-content-bottom-second-section-header {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.gp-third-content-bottom-first-section-image {
  display: flex;
  width: 50px;
  height: 40px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
  overflow: hidden;
}

.gp-third-content-bottom-first-section-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  width: 120px;
}

.gp-third-content-bottom-second-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gp-third-content-bottom-second-section-header {
  display: flex;
  align-items: center;
}

.gp-third-content-bottom-second-section-header-text-up {
  color: var(--Success-700, #38602b);

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
}

.gp-third-content-bottom-second-section-header-text-down {
  color: var(--Error-700, #bc1919);

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
}

.gp-third-content-bottom-second-section-fotter {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.gp-third-content-bottom-third-section-private {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: var(--secondary-electric-violet-100, #dde1ff);
  width: fit-content;
}

.gp-third-content-bottom-third-section-custom {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: var(--Accent-100, #fff9c1);
  width: fit-content;
}

.gp-third-content-bottom-third-section-text {
  color: var(--secondary-electric-violet-900, #362689);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
}

.gp-main-content-header-button-custom {
  display: flex;
  width: 100px;
  padding: 6px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid var(--secondary-electric-violet-700, #4e2bd7);
  opacity: 0.5;
}

.general-page-fourth-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.general-page-footer {
  position: absolute;
  bottom: 25px;
  width: 100%;
}

.general-page-fourth-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.gp-main-content-header-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.gp-news-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.gp-news-content-group {
  display: flex;
  align-items: center;
  gap: 16px;
}

.gp-news-content {
  display: flex;
  width: max-content;
  padding: 15px 15px 15px 0;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 12px;
  background: linear-gradient(90deg, #fffbde 0.41%, #fff3a2 99.59%);
}

.gp-news-content-custom {
  display: flex;
  width: max-content;
  padding: 15px 15px 15px 0;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 12px;
  background: linear-gradient(90deg, #fff2f2 0.41%, #ffdede 99.59%);
}

.gp-news-content-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
}

.gp-news-content-text-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: var(--space-8px, 8px);
}

.gp-news-content-text-header {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 01/P3 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  align-self: stretch;
}

.gp-news-content-text-footer {
  color: var(--Neutral-800, #40414c);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.125rem */
  width: 199px;
}

.gp-news-content-image {
  /* position: absolute;
bottom: 25px; */
  width: 115px;
  height: 115px;
}

.dashboard-header-container {
  display: flex;
  padding: 12px var(--space-0, 0px);
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-bottom: 1px solid var(--secondary-electric-violet-50, #ecefff);
  width: 100%;
}

.dashboard-header-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Heading/H4 */
  font-family: Manrope;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.5rem */
}

.d-first-section-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.general-selector-continer {
  display: flex;
  align-items: center;
  gap: 133px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
}

.general-selector-group {
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 32px;
  background: var(--Neutral-100, #eeeef0);
}

.general-selector {
  display: flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
}

.general-selector-active {
  display: flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--secondary-electric-violet-800, #3f25ae);
  cursor: pointer;
  user-select: none;
}

.general-selector-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 150% */
  /* width: 34px; */
}

.general-selector-text-active {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  text-align: center;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 150% */
  /* width: 34px; */
}

.dashboard-first-section-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  width: 100%;
}

.general-selector-content {
  display: flex;
  /* height: 184px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.general-selector-content-group {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.general-s-content {
  display: flex;
  min-width: 206px;
  padding: 16px 15px 103px 16px;
  justify-content: center;
  align-items: center;
  background: var(--Neutral-50, #f7f7f8);
  height: 184px;
  border-radius: 8px;
}

.general-s-content-active {
  display: flex;
  padding: 16px 15px 103px 16px;
  justify-content: center;
  /* align-items: center; */
  background: linear-gradient(180deg, #21174f 0%, #3c2d7d 100%);
  min-width: 335px;
  position: relative;
  height: 184px;
  border-radius: 8px;
}

.g-s-content {
  display: flex;
  /* width: 175px; */
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  width: 100%;
}

.g-s-content-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.g-s-content-header-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.g-s-content-header-text-active {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.g-s-content-header-footer {
  color: var(--Neutral-950, #0c0c0e);

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1.5rem */
}

.g-s-content-header-footer-active {
  color: var(--Neutral-White-100, #fff);

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1.5rem */
}

.g-s-content-header-footer-foot {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.g-s-content-header-footer-foot-active {
  color: var(--Neutral-White-100, #fff);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.g-s-content-button {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  background: var(--secondary-electric-violet-200, #c2c7ff);
}

.g-s-content-button-active {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  background: var(--secondary-electric-violet-900, #362689);
  cursor: pointer;
  user-select: none;
}

.g-s-content-button-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.g-s-content-button-text-active {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.g-s-content-overlay {
  position: absolute;
  right: 0px;
  top: 0px;
}

.dashboard-transaction-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  align-self: stretch;
  width: 400px;
}

.dashboard-transaction-date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  align-self: stretch;
}

.dashboard-transaction-time {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  align-self: stretch;
}

.dashboard-transaction-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  align-self: stretch;
}

.d-transaction-details-header {
  display: flex;
  padding: 10px 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  align-self: stretch;
  background: var(--secondary-electric-violet-50, #ecefff);
}

.d-transaction-details-header-text {
  color: var(--Neutral-900, #383842);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.d-transaction-details-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  align-self: stretch;
}

.d-transaction-details-icon {
  display: inline-flex;
  padding: var(--space-8px, 8px);
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  background: var(--Neutral-50, #f7f7f8);
}

.d-transaction-details-body-content {
  display: flex;
  padding-bottom: var(--space-8px, 8px);
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  border-bottom: 1px solid var(--Neutral-100, #eeeef0);
}

.d-transaction-details-content-text {
  color: var(--Neutral-900, #383842);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  width: 188px;
}

.d-transaction-date-body-content {
  display: flex;
  padding-bottom: var(--space-8px, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;
  border-bottom: 1px solid var(--Neutral-100, #eeeef0);
}

.d-transaction-date-body-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.d-transaction-date-content-text {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.d-transaction-amount-content-text {
  color: var(--Success-600, #41732f);
  text-align: right;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
  flex: 1 0 0;
}

.d-transaction-amount-content-text-red {
  color: var(--Error-600, #e23636);
  text-align: right;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
  flex: 1 0 0;
}

.d-transaction-transaction-header {
  display: flex;
  padding: 10px 24px;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
  background: var(--secondary-electric-violet-50, #ecefff);
  justify-content: flex-end;
}

.g-s-content-footer-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  text-decoration-line: underline;
  cursor: pointer;
  user-select: none;
}

.general-s-card {
  width: 335px;
  height: 184px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  flex-shrink: 0;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-100, #eeeef0);
  padding: 16px 16px 0 12px;
}

.general-s-card-active {
  width: 335px;
  height: 184px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  flex-shrink: 0;
  border-radius: var(--space-8px, 8px);
  background: linear-gradient(180deg, #21174f 0%, #3c2d7d 100%);
  padding: 16px 16px 0 12px;
  position: relative;
}

.g-s-card-footer-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  text-decoration-line: underline;
  cursor: pointer;
  user-select: none;
}

.g-s-card-content {
  display: flex;
  width: 303px;
  justify-content: space-between;
  align-items: flex-start;
}

.g-s-card-content-active {
  display: flex;
  width: 303px;
  height: 62px;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
}

.g-s-card-content-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.g-s-card-content-header-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.g-s-card-content-header-fotter {
  display: flex;
  align-items: center;
  gap: 9px;
}

.g-s-card-currency-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1.5rem */
}

.g-s-hide-balance-container {
  display: flex;
  padding: var(--space-8px, 8px) var(--space-0, 0px);
  align-items: center;
  gap: var(--space-8px, 8px);
}

.g-s-card-content-text-active {
  color: var(--Neutral-300, #b6b7c3);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  letter-spacing: 0.18rem;
}

.g-s-last-card-number {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: 0.13125rem;
  width: 40px;
}

.g-s-card-content-header-bottom {
  display: flex;
  padding: var(--space-0, 0px) 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.g-s-hide-card-number-container {
  display: flex;
  width: 40px;
  align-items: center;
  gap: 6px;
}

.g-s-card-content-fotter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.g-s-card-fotter-first-section {
  display: flex;
  align-items: center;
  gap: 12px;
}

.g-s-card-first-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.g-s-card-second-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.g-s-card-first-text {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.g-s-card-first-text-fotter {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));

  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
}

.g-s-hide-cvv-container {
  display: flex;
  width: 21px;
  padding: 6px var(--space-0, 0px);
  align-items: center;
  gap: 4px;
}

.g-s-card-expose-icon {
  cursor: pointer;
  z-index: 3;
}

.g-s-card-hide-icon {
  cursor: pointer;
  z-index: 3;
}

.card-pin-popup {
  display: flex;
  width: 364px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 45px;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.12);
}

.general-popup-background {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* padding-top: 25px; */
  overflow: auto;
}

.card-pin-popup-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.card-pin-popup-content-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.general-popup-close-icon {
  position: absolute;
  right: 0px;
  top: 5.5px;
}

.card-pin-popup-content-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.card-pin-popup-content-header-container {
  display: flex;
  height: 36px;
  align-items: center;
  gap: 156px;
  align-self: stretch;
  position: relative;
}

.card-pin-popup-content-header-first {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.c-p-popup-content-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 1.2rem */
}

.otp-input-group {
  display: flex;
  width: 335px;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  width: 100%;
}

.otp-input {
  display: flex;
  padding: 20px 12px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
  width: 25%;
  text-align: center;
}

.otp-timer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.otp-timer-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.otp-timer-text-red {
  color: var(--Error-600, #e23636);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.otp-resend-text {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  cursor: pointer;
  user-select: none;
}

.otp-resend-text-active {
  color: var(--secondary-electric-violet-600, #603ff4);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  cursor: pointer;
  user-select: none;
}

.st-otp-icon {
  cursor: pointer;
  user-select: none;
}

.startup-button {
  display: inline-flex;
  padding: 14px 126.5px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  border-radius: var(--space-8px, 8px);
  opacity: 0.5;
  background: var(--primary-bitter-lemon-500, #cfe600);
  cursor: pointer;
  user-select: none;
  width: 100%;
  margin-bottom: 50px;
}

.startup-button-active {
  display: inline-flex;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  border-radius: var(--space-8px, 8px);
  background: var(--primary-bitter-lemon-500, #cfe600);
  cursor: pointer;
  user-select: none;
  width: 100%;
  margin-bottom: 50px;
}

.startup-button-click {
  display: inline-flex;
  padding: 14px 126.5px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-950, #0c0c0e);
  cursor: pointer;
  user-select: none;
  width: 100%;
  margin-bottom: 50px;
}

.st-button-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.orders-page-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.orders-page-content-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.orders-page-content-header-container-button-group {
  display: flex;
  width: 1022px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.orders-page-content-header-container-buttons {
  display: flex;
  width: 402px;
  padding: 4px;
  align-items: center;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
}

.orders-page-content-header-container-button-active {
  display: flex;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  background: var(--secondary-electric-violet-700, #4e2bd7);
  cursor: pointer;
  user-select: none;
}

.orders-page-content-header-container-button {
  display: flex;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.orders-page-content-header-container-button-text-active {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  text-align: center;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 150% */
}

.orders-page-content-header-container-button-text {
  color: var(--Neutral-800, #40414c);
  text-align: center;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 150% */
}

.orders-page-content-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.orders-page-content-body-header {
  display: flex;
  padding: 5px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--Neutral-50, #f7f7f8);
}

.orders-page-content-body-header-closed {
  display: flex;
  padding: 5px 20px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  background: var(--Neutral-50, #f7f7f8);
}

.orders-page-content-body-main {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  cursor: pointer;
  user-select: none;
  position: relative;
}

.orders-page-content-body-main-primary {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  cursor: pointer;
  user-select: none;
  position: relative;
}

.create-secondary-page-content-body-main {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  cursor: pointer;
  user-select: none;
  position: relative;
}

.create-secondary-page-content-body-main-active {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--secondary-electric-violet-800, #3f25ae);
  cursor: pointer;
  user-select: none;
  position: relative;
}

.create-secondary-page-content-body-main:hover {
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--secondary-electric-violet-800, #3f25ae);
}

.orders-page-content-body-main-closed {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
}

.orders-page-content-body-header-first {
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 15%;
}

.orders-page-content-body-header-first-closed {
  display: flex;
  gap: 4px;
  min-width: 15%;
}

.orders-page-content-body-header-text-first {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  max-width: 135px;
  word-wrap: break-word;
}

.orders-page-content-body-header-text {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  width: 100px;
  word-wrap: break-word;
}

.orders-page-content-body-header-line {
  width: 0px;
  height: 16px;
  stroke-width: 1px;
  stroke: var(--Neutral-200, #d8d9df);
}

.orders-page-content-body-header-second {
  display: flex;
  width: 244px;
  align-items: center;
  gap: 40px;
}

.orders-page-content-body-header-second-text {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  width: 100px;
  flex-shrink: 0;
  word-wrap: break-word;
}

.orders-page-content-body-header-second-text-header {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  flex-shrink: 0;
  word-wrap: break-word;
  width: max-content;
}

.orders-page-content-body-header-last {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  min-width: 15%;
}

.orders-page-content-body-main-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.orders-page-content-body-main-first-header {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  flex-direction: column;
}

.orders-page-content-body-main-first-header-title {
  display: flex;
  align-items: center;
  gap: 4px;
}

.orders-page-content-body-main-first-title {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  width: 151px;
  word-wrap: break-word;
}

.orders-page-content-body-main-first-label {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: var(--secondary-electric-violet-50, #ecefff);
}

.orders-page-content-body-header-actions {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 15%;
}

.orders-page-content-body-header-actions-primary {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 15%;
}

.orders-page-content-body-header-actions-header {
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 15%;
}

.orders-page-content-body-header-action-buy {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: var(--Success-100, #e0f0d7);
}

.orders-page-content-body-header-action-sell {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: var(--Error-100, #fee2e2);
}

.orders-page-content-body-header-action-buy-text {
  color: var(--Success-600, #41732f);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
  max-width: 25px;
  word-wrap: break-word;
}

.orders-page-content-body-header-action-sell-text {
  color: var(--Error-800, #9b1919);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
  max-width: 25px;
  word-wrap: break-word;
}

.orders-page-content-body-header-action-amount {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  width: 80px;
  word-wrap: break-word;
}

.orders-history-table-arrow-icon {
  position: absolute;
  right: 45%;
}

.orders-page-content-body-header-quantity {
  display: flex;
  align-items: center;
  gap: 6px;
}

.orders-page-content-body-header-text-last {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  max-width: 100px;
  word-wrap: break-word;
}

.orders-page-content-body-header-action-icons {
  display: none;
  padding: 6px;
  align-items: center;
  gap: var(--space-8px, 8px);
  /* border-radius: var(--space-8px, 8px);
border: 1px solid var(--Neutral-100, #EEEEF0); */
  width: 15%;
}

.orders-page-content-body-header-action-icons-container {
  display: flex;
  width: max-content;
  padding: 6px;
  align-items: center;
  gap: var(--space-8px, 8px);
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
}

.orders-page-content-body-header-action-icon {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;
}

.orders-page-content-body-header-action-icon-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  width: 80%;
}

.orders-page-content-body-main:hover
  .orders-page-content-body-header-action-icons {
  display: flex;
}
.orders-page-content-body-main:hover .orders-page-content-body-header-actions {
  display: none;
}

.orders-page-content-body-header-actions-closed {
  display: flex;

  flex-direction: column;
  align-items: flex-start;

  gap: 12px;
}

.orders-page-content-body-header-actions-closed-last {
  display: flex;

  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 45px;
}

.orders-page-content-body-main-closed-button {
  width: 125px;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--primary-bitter-lemon-500, #cfe600);
  cursor: pointer;
  user-select: none;
}

.orders-page-content-body-main-closed-button-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}
.orders-page-content-body-main-closed-button-text-green {
  color: var(--Success-600, #41732f);
  text-align: center;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}

.orders-page-content-body-main-closed-button-text-red {
  color: var(--Error-800, #9b1919);
  text-align: center;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}

.orders-page-closed-orders-footer {
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: var(--space-8px, 8px);
  background: var(--Success-100, #e0f0d7);
  margin-top: 100px;
}

.orders-page-closed-orders-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.orders-page-closed-orders-footer-first {
  display: flex;
  align-items: flex-start;
  gap: 2px;
}

.orders-page-closed-orders-footer-first-text-gain {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
  width: 90px;
}

.orders-page-closed-orders-footer-first-text-loss {
  color: var(--Error-700, #bc1919);
  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
  width: 90px;
}

.orders-page-closed-orders-footer-second-text-gain {
  color: var(--Success-700, #38602b);

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
  max-width: 136px;
  min-width: 136px;
  word-wrap: break-word;
  display: flex;
  flex-direction: row-reverse;
}

.order-history-table-separator {
  position: absolute;
  left: 55%;
}

.orders-table-arrow-icon {
  position: absolute;
  right: 55%;
}

.orders-page-closed-orders-footer-second-text-default {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
  min-width: 136px;
  max-width: 136px;
  word-wrap: break-word;
  display: flex;
  flex-direction: row-reverse;
}

.orders-page-closed-orders-footer-second-text-loss {
  color: var(--Error-700, #bc1919);

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
  max-width: 136px;
  word-wrap: break-word;
}

.orders-page-closed-orders-footer-icon {
  cursor: pointer;
  user-select: none;
}

.orders-page-closed-orders-footer-first-expand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.orders-page-closed-orders-footer-first-expand-title {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.orders-page-closed-orders-footer-first-expand-text-gain {
  color: var(--Success-700, #38602b);
  text-align: right;

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.orders-page-closed-orders-footer-first-expand-text-loss {
  color: var(--Error-700, #bc1919);
  text-align: right;

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.orders-page-closed-orders-footer-first-expand-info {
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Success-50, #f1f8ed);
}

.orders-page-closed-orders-footer-first-expand-info-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.orders-page-content-body-header-action-executed {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 2px;
  border-radius: 2px;
  background: var(--Success-100, #e0f0d7);
  min-width: max-content;
}

.orders-page-content-body-header-action-rejected {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 2px;
  border-radius: 2px;
  background: var(--Error-100, #fee2e2);
  min-width: max-content;
}

.orders-page-content-body-header-action-executed-text {
  color: var(--Success-600, #41732f);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
  /* width: 50px; */
}

.orders-page-content-body-header-action-rejected-text {
  color: var(--Error-700, #bc1919);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
}

.orders-page-content-body-header-actions-history {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  width: 165px;
  padding-left: 22px;
  gap: 12px;
}

.orders-page-content-body-header-second-text-history {
  padding-left: 22px;
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  width: 100px;
  flex-shrink: 0;
  word-wrap: break-word;
}

.orders-page-content-body-header-action-amount-closed {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  width: 100px;
  word-wrap: break-word;
}

.orders-page-order-history-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.orders-page-order-history {
  display: flex;
  padding: 16px 12px;
  align-items: flex-end;
  gap: 12px;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-950, #21174f);
}

.orders-page-order-history-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.orders-page-order-history-header {
  color: var(--common-white_states-main, #fff);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  width: 144px;
  height: 17px;
}

.orders-page-order-history-body {
  color: var(--common-white_states-main, #fff);

  /* Paragraph 01/P3 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
}

.orders-page-order-history-footer {
  color: var(--Neutral-200, #d8d9df);

  /* Paragraph 04/P2 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 0.875rem */
  width: 73px;
  height: 13px;
}

.orders-page-order-history-line {
  background: #fff;
  width: 1px;
  height: 64px;
}

.portfolio-page-footer-content {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  cursor: pointer;
}

.portfolio-page-footer-content:hover {
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--Neutral-50, #f7f7f8);
}

.portfolio-page-footer-content-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
}

.portfolio-page-footer-content-main {
  display: flex;
  align-items: flex-start;
  gap: 78px;
  align-self: stretch;
}

.portfolio-page-footer-content-main-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  min-width: 25%;
}

.portfolio-page-footer-content-main-first-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.portfolio-page-footer-content-main-first-title {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  width: 100%;
  word-break: break-all;
}

.portfolio-page-footer-content-main-first-text {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  width: 100%;
  word-wrap: break-word;
}

.portfolio-page-footer-content-main-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  min-width: 15%;
}

.portfolio-page-footer-content-main-second-title {
  color: var(--Neutral-700, #4a4a5a);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
  align-self: stretch;
  max-width: 138px;
  word-wrap: break-word;
}

.portfolio-page-footer-content-main-second-text {
  align-self: stretch;
  color: var(--Neutral-800, #40414c);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  min-width: 100px;
  max-width: 100px;
  word-wrap: break-word;
}

.portfolio-page-footer-content-main-third {
  display: flex;
  align-items: flex-start;
  gap: 100px;
}

.portfolio-page-footer-content-main-last-text-gain {
  color: var(--Success-700, #38602b);
  text-align: right;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.225rem */
  max-width: 138px;
  word-wrap: break-word;
  flex: 1 0 0;
  text-align: right;
}

.portfolio-page-footer-content-main-last-text-loss {
  color: var(--Error-700, #bc1919);
  text-align: right;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.225rem */
  max-width: 138px;
  word-wrap: break-word;
  flex: 1 0 0;
  text-align: right;
}

.portfolio-page-footer-content-main-third-title-container {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
}

.portfolio-page-footer-content-main-third-text {
  color: var(--Success-700, #38602b);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  max-width: 138px;
  word-wrap: break-word;
}

.portfolio-page-footer-content-main-last {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 6px;
  flex: 1 0 0;
}

.portfolio-page-content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);

  /* Shadow Nav 1 */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
}

.portfolio-page-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.portfolio-page-content-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.portfolio-page-content-line-chart {
  display: flex;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
  background: var(--Neutral-50, #f7f7f8);
}

.portfolio-page-content-footer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
  flex-wrap: wrap;
}
.portfolio-page-content-header-main {
  display: flex;
  /* width: 198px; */
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
}

.portfolio-page-content-header-main-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.portfolio-page-content-header-main-text-container {
  display: flex;
  align-items: center;
}

.portfolio-page-content-header-main-first-text {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.portfolio-page-content-header-main-second-text {
  color: var(--Success-600, #41732f);

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.portfolio-page-content-header-button {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  background: var(--secondary-electric-violet-900, #362689);
  cursor: pointer;
  user-select: none;
}

.portfolio-page-content-header-button-text {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  flex: 1;
  width: auto;
  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  word-wrap: break-word;
}

.portfolio-page-content-body-container {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.portfolio-page-content-body-section {
  display: flex;
  padding: var(--space-8px, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid var(--Neutral-200, #d8d9df);
}

.portfolio-page-content-line-chart-main1 {
  background: var(--primary-bitter-lemon-600, #9eb500);
  height: 4px;
  width: 25%;
}
.portfolio-page-content-line-chart-main2 {
  height: 4px;
  background: var(--secondary-electric-violet-400, #7a76ff);
  width: 25%;
}
.portfolio-page-content-line-chart-main3 {
  height: 4px;
  background: var(--Accent-500, #f2af00);
  width: 25%;
}
.portfolio-page-content-line-chart-main4 {
  height: 4px;
  background: var(--Success-400, #7cb962);
  width: 25%;
}

.portfolio-page-content-footer-label {
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-100, #eeeef0);
}

.portfolio-page-content-footer-label-eclipse1 {
  width: 6px;
  height: 6px;
  background-color: var(--primary-bitter-lemon-600, #9eb500);
  border-radius: 50%;
}

.portfolio-page-content-footer-label-eclipse2 {
  width: 6px;
  height: 6px;
  background-color: var(--secondary-electric-violet-400, #7a76ff);
  border-radius: 50%;
}

.portfolio-page-content-footer-label-eclipse3 {
  width: 6px;
  height: 6px;
  background-color: var(--Accent-500, #f2af00);
  border-radius: 50%;
}

.portfolio-page-content-footer-label-eclipse4 {
  width: 6px;
  height: 6px;
  background-color: var(--Success-400, #7cb962);
  border-radius: 50%;
}

.portfolio-page-content-footer-text {
  color: var(--Neutral-700, #4a4a5a);

  /* Paragraph 04/P2 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 0.875rem */
}

.portfolio-page-toolset-content-container {
  display: flex;
  /* width: 335px; */
  align-items: center;
  align-content: center;
  gap: 12px;
  flex-wrap: wrap;
}

.portfolio-page-toolset-content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  width: 161px;
  cursor: pointer;
  user-select: none;
}

.portfolio-page-toolset-content-text {
  color: var(--Neutral-900, #383842);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.portfolio-page-toolset-content:hover {
  background: var(--Neutral-50, #f7f7f8);
}

.create-secondary-page-header {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.create-issue-page-content {
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
}

.create-secondary-page-product-label {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.create-secondary-page-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: sticky;
}

.create-secondary-page-footer-button {
  display: flex;
  flex: 1;

  padding: 6px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--primary-bitter-lemon-500, #cfe600);
  cursor: pointer;
  user-select: none;
}

.create-secondary-page-footer-button-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}

.gp-separator-icon {
  cursor: pointer;
}

.loans-page-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.loans-page-header-container {
  display: flex;
  padding: 12px var(--space-0, 0px);
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-bottom: 1px solid var(--secondary-electric-violet-50, #ecefff);
}

.loans-page-header-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.loans-page-header-content-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.loans-page-header-content-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
}

.loans-page-header-content-first-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.loans-page-header-content-first-content {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-50, #ecefff);
}

.loans-page-header-content-first-main {
  display: flex;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
}

.loans-page-header-content-first-line {
  width: 1px;
  height: 26px;
  background: #dde1ff;
}

.loans-page-header-content-first-main-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.loans-page-header-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.loans-page-header-label-text {
  width: 373px;
  color: var(--Neutral-400, #8f91a1);
  text-align: right;

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.loans-page-header-label-button {
  display: flex;
  width: 100px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-600, #603ff4);
  cursor: pointer;
  user-select: none;
}

.loans-page-header-label-button-text {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  text-align: center;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}

.loans-page-header-content-first-header-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
  align-self: stretch;
}

.loans-page-header-label-button {
  display: flex;
  width: 100px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-600, #603ff4);
  cursor: pointer;
  user-select: none;
}

.loans-page-header-content-second-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
}

.loans-page-header-content-second-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.loans-page-header-content-second-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
}

.loans-page-header-content-second-header-first {
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 32px;
  background: var(--Neutral-100, #eeeef0);
}

.loans-page-header-content-second-header-content {
  display: flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
}

.loans-page-header-content-second-header-content-active {
  display: flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--secondary-electric-violet-800, #3f25ae);
  cursor: pointer;
  user-select: none;
}

.loans-page-header-content-second-header-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 150% */
}

.loans-page-header-content-second-header-text-active {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  text-align: center;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 150% */
}

.loans-page-header-content-second-header-second {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.loans-page-header-content-second-header-second-icon {
  display: flex;
  padding: 6px;
  align-items: center;
  gap: var(--space-8px, 8px);
  border-radius: 32px;
  background: var(--Neutral-50, #f7f7f8);
}

.loans-page-header-content-second-main {
  display: flex;
  width: 1026px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.loans-page-header-content-second-main {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.loans-page-header-content-second-main-container {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.loans-page-header-content-second-main-content {
  display: flex;
  width: 335px;
  height: 205px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.loans-page-header-content-second-main-content-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  width: 335px;
}

.loans-page-header-content-second-main-content-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.loans-page-header-content-second-main-content-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.loans-page-header-content-second-main-content-header-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.loans-page-header-content-second-main-header-text {
  color: var(--Neutral-700, #4a4a5a);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.loans-page-header-content-second-main-footer-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1.5rem */
}

.loans-page-header-content-second-main-footer-text-label {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.loans-page-header-content-second-main-content-header-button {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  background: var(--secondary-electric-violet-900, #362689);
}

.loans-page-header-content-second-main-content-header-button-text {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.loans-page-header-content-second-main-content-footer-content {
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--secondary-electric-violet-50, #ecefff);
}

.loans-page-header-content-second-main-content-footer-content-header {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.loans-page-header-content-second-main-content-footer-content-footer {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.loans-page-main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}

.loans-page-main-content-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.loans-page-main-content-footer-first {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.loans-page-main-content-footer-first-details-container {
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
}

.loans-page-main-content-footer-first-details {
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px) 0px 0px var(--space-8px, 8px);
  background: var(--secondary-electric-violet-50, #ecefff);
}

.loans-page-main-content-footer-first-details-text {
  color: var(--Neutral-900, #383842);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.loans-page-main-content-footer-first-date-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
}

.loans-page-main-content-footer-first-date {
  display: flex;
  padding: 10px var(--space-0, 0px);
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--secondary-electric-violet-50, #ecefff);
}

.loans-page-main-content-footer-first-loan-amount-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
}

.loans-page-main-content-footer-first-loan-amount {
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--secondary-electric-violet-50, #ecefff);
}

.loans-page-main-content-footer-first-status-container {
  display: flex;
  width: 149px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
}

.loans-page-main-content-footer-first-status {
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 0px var(--space-8px, 8px) var(--space-8px, 8px) 0px;
  background: var(--secondary-electric-violet-50, #ecefff);
}

.loans-page-main-content-footer-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.loans-page-main-content-footer-second-container {
  display: flex;
  padding-bottom: var(--space-8px, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.loans-page-main-content-footer-second-content {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  background: var(--Accent-100, #fff9c1);
  width: 600px;
}

.loans-page-main-content-footer-second-content-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.loans-page-main-content-footer-second-content-text {
  color: var(--Accent-800, #89490a);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.loans-popup-page-content {
  display: flex;
  width: 394px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.loans-popup-page-content-container {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-50, #ecefff);
}

.loans-popup-page-content-header {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 01/P3 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  align-self: stretch;
}

.loans-popup-page-content-footer {
  color: var(--Neutral-700, #4a4a5a);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  align-self: stretch;
}

.loans-page-main-content-footer-second-content-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.loans-page-main-content-footer-second-content-main-header {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.loans-page-main-content-footer-second-content-main-footer {
  display: flex;
  padding-bottom: var(--space-8px, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--Neutral-100, #eeeef0);
}

.loans-page-main-content-footer-second-content-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.loans-page-main-content-footer-second-content-main-header {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.loans-page-main-content-footer-second-content-main-header-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 400px;
}

.loans-page-main-content-footer-second-content-main-header-first-container {
  display: flex;
  height: 60px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.loans-page-main-content-footer-second-content-main-header-first-content {
  display: flex;
  padding-bottom: var(--space-8px, 8px);
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
}

.loans-page-main-content-footer-second-content-main-header-first-content-icon {
  display: inline-flex;
  padding: var(--space-8px, 8px);
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  background: var(--Neutral-50, #f7f7f8);
}

.loans-page-main-content-footer-second-content-main-header-first-content-title {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  width: 188px;
}

.loans-page-main-content-footer-second-content-main-header-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
}

.loans-page-main-content-footer-second-content-main-header-second-container {
  display: flex;
  height: 60px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.loans-page-main-content-footer-second-content-main-header-second-content {
  display: flex;
  padding-bottom: var(--space-8px, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;
}

.loans-page-main-content-footer-second-content-main-header-second-content-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.loans-page-main-content-footer-second-content-main-header-first-content-label {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.loans-page-main-content-footer-second-content-main-header-fourth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
}

.loans-page-main-content-footer-second-content-main-header-fourth-container {
  display: flex;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.loans-page-main-content-footer-second-content-main-header-fourth-content {
  display: flex;
  padding: 12px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
}

.loans-page-main-content-footer-second-content-main-header-fourth-content-text {
  color: var(--Success-600, #41732f);
  text-align: right;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.loans-page-main-content-footer-second-content-main-header-last {
  display: flex;
  width: 149px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
}

.loans-page-main-content-footer-second-content-main-header-last-container {
  display: flex;
  height: 60px;
  padding: var(--space-0, 0px) 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  align-self: stretch;
  position: relative;
}

.loans-page-main-content-footer-second-content-main-header-last-content-on {
  border-radius: 4px;
  background: var(--Success-100, #e0f0d7);
  display: flex;
  padding: 4px 6px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  left: 24px;
  top: 15px;
  width: max-content;
}

.loans-page-main-content-footer-second-content-main-header-last-content-text-on {
  color: var(--Success-800, #2f4e26);
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 0.875rem */
}

.loans-page-main-content-footer-second-content-main-footer {
  display: flex;
  padding-bottom: var(--space-8px, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--Neutral-100, #eeeef0);
}

.loans-page-main-content-footer-second-content-main-footer-container {
  display: flex;
  width: 482px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
}

.loans-page-main-content-footer-second-content-main-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.loans-page-main-content-footer-second-content-main-footer-content-first {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.loans-page-main-content-footer-second-content-main-footer-content-title {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.loans-page-main-content-footer-second-content-main-footer-content-label {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.loans-page-main-content-footer-second-content-main-header-last-content-pen {
  display: flex;
  width: max-content;
  padding: 4px 6px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 24px;
  top: 15px;
  border-radius: 4px;
  background: var(--secondary-electric-violet-100, #dde1ff);
}

.loans-page-main-content-footer-second-content-main-header-last-content-text-pen {
  color: var(--secondary-electric-violet-900, #362689);

  /* Paragraph 04/P2 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 0.875rem */
  width: 79px;
  flex-shrink: 0;
}

.loans-page-header-content-second-main-content-custom {
  display: flex;
  width: 206px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-50, #ecefff);
}

.loans-page-header-content-second-main-content-custom-container {
  display: flex;
  width: 206px;
  height: 205px;
  padding: 16px 15px 124px 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--Neutral-50, #f7f7f8);
  border-radius: var(--space-8px, 8px);
}

.loans-page-header-content-second-main-content-custom-header {
  display: flex;
  width: 175px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
}

.loans-page-header-content-second-main-content-custom-header-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.loans-page-header-content-second-main-content-custom-header-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.loans-page-header-content-second-main-content-custom-footer-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1.5rem */
}

.loans-page-header-content-second-main-content-custom-header-button {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  background: var(--secondary-electric-violet-200, #c2c7ff);
}

.loans-page-header-content-second-main-content-custom-header-button-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.loans-page-header-content-second-main-content-header-label-container {
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.loans-page-header-content-second-main-content-header-label-title {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
}

.loans-page-header-content-second-main-content-header-label-footer {
  color: var(--Success-600, #41732f);

  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
}

.new-issue-page-header {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
}

.new-issue-page-header-first {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.new-issue-page-header-first-header {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.new-issue-page-header-first-footer-body {
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.new-issue-page-header-first-footer {
  display: flex;
  width: 195px;
  height: 45px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-200, #d8d9df);
  cursor: pointer;
}

.new-issue-page-header-first-footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.new-issue-page-header-first-footer-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.new-issue-page-header-first-footer-icon {
  cursor: pointer;
}

.new-issue-page-header-first-footer-second {
  display: flex;
  width: 195px;
  padding: 16px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 51px;
  right: 0;
}

.new-issue-page-header-first-footer-line {
  width: 100%;
  height: 1px;
  background: #eeeef0;
}

.new-issue-page-header-first-footer-second-text {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  cursor: pointer;
  user-select: none;
}

.new-issue-page-header-second {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.new-issue-page-header-second-header {
  display: flex;
  align-items: center;
  gap: 4px;
}

.new-issue-page-header-second-footer {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.new-issue-page-header-second-header-text {
  color: var(--text-secondary, #7a7a83);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 200% */
}

.new-issue-page-header-second-header-label {
  color: var(--text-secondary, #7a7a83);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
}

.new-issue-page-header-second-footer-label {
  display: flex;
  width: 56px;
  height: 17px;
  flex-direction: column;
  justify-content: center;
  color: var(--secondary-electric-violet-700, #4e2bd7);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 200% */
}

.new-issue-page-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.new-issue-page-main-container {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  cursor: pointer;
}

.new-issue-page-main-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
}

.new-issue-page-main-content-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.new-issue-page-main-content-body-first {
  display: flex;
  align-items: center;
  gap: 50px;
}

.new-issue-page-main-content-body-first-container {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.new-issue-page-main-content-body-first-icon {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
  overflow: hidden;
}

.new-issue-page-main-content-body-first-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.new-issue-page-main-content-body-first-title-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.new-issue-page-main-content-body-first-title {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  min-width: 200px;
}

.new-issue-page-main-content-body-first-label {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.new-issue-page-main-content-body-second {
  display: flex;
  width: 138px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.new-issue-page-main-content-body-title {
  color: var(--Neutral-700, #4a4a5a);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
  align-self: stretch;
}

.new-issue-page-main-content-body-label {
  color: var(--Neutral-900, #383842);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.new-issue-page-main-content-body-last {
  display: flex;
  width: 244px;
  align-items: flex-start;
  gap: 40px;
}

.new-issue-page-main-content-body-last-container {
  display: flex;
  width: 100px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
}

.portfolio-page-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.portfolio-page-actions-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.portfolio-page-actions-content {
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--common-white_states-main, #fff);
}

.portfolio-page-actions-content-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.portfolio-page-actions-content-footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
}

.portfolio-page-actions-content-footer-button {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--secondary-electric-violet-500, #6756fe);
}

.portfolio-page-actions-content-footer-button-text {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  text-align: center;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}

.portfolio-page-actions-content-header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.portfolio-page-actions-content-header-first {
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.portfolio-page-actions-content-header-first-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.portfolio-page-actions-content-header-first-title-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.portfolio-page-actions-content-header-first-label {
  display: flex;
  align-items: center;
  gap: 4px;
}

.portfolio-page-actions-content-header-first-label-first {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: var(--secondary-electric-violet-100, #dde1ff);
}

.portfolio-page-actions-content-header-first-label-first-text {
  color: var(--secondary-electric-violet-900, #362689);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
}

.portfolio-page-actions-content-header-first-label-second {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: var(--Neutral-100, #eeeef0);
}

.portfolio-page-actions-content-header-first-label-second-text {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
}

.portfolio-page-actions-content-header-second {
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
}

.portfolio-page-actions-content-header-second-container {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
  border-radius: 4px;
  background: var(--Success-50, #f1f8ed);
}

.portfolio-page-actions-content-header-second-text {
  color: var(--Success-700, #38602b);

  /* Paragraph 04/P2 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 0.875rem */
}

.portfolio-page-actions-content-header-last {
  display: flex;
  width: 430px;
  padding: var(--space-8px, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  border-radius: 6px;
}

.portfolio-page-actions-content-header-last-title {
  color: var(--Neutral-900, #383842);

  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
}

.portfolio-page-actions-content-header-last-label {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  align-self: stretch;
}

.general-s-content-main-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: flex-start;
  width: 100%;
}

@media screen and (min-width: 300px) and (max-width: 1200px) {
  .dashboard-content-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    overflow: hidden;
  }
  .dashboard-content-header-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .dashboard-content-header-buttons {
    display: flex;
    align-items: flex-start;
    gap: var(--space-8px, 8px);
  }

  .dashboard-content-button {
    display: flex;
    height: 32px;
    padding: var(--space-8px, 8px);
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--secondary-electric-violet-700, #4e2bd7);
    overflow: hidden;
    cursor: pointer;
    user-select: none;
  }

  .dashboard-content-button-active {
    display: flex;
    height: 32px;
    padding: var(--space-8px, 8px);
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--secondary-electric-violet-700, #4e2bd7);
    overflow: hidden;
    cursor: pointer;
    user-select: none;
  }

  .dashboard-content-button-text {
    color: var(--secondary-electric-violet-700, #4e2bd7);

    /* Paragraph 03/P2 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.05rem */
  }

  .dashboard-content-button-text-active {
    color: var(--Neutral-White-100, var(--common-white_states-main, #fff));

    /* Paragraph 03/P2 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.05rem */
  }

  .dashboard-content-body {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .d-content-body-container {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: var(--space-8px, 8px);
    border: 1px solid var(--Neutral-50, #f7f7f8);
    background: var(--common-white_states-main, #fff);
  }

  .d-content-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .d-content-body-text-container {
    display: flex;
    align-items: center;
    gap: var(--space-8px, 8px);
  }

  .d-content-text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .d-content-text-container {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .d-content-text {
    color: var(--Neutral-950, #0c0c0e);

    /* Paragraph 02/P2 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.225rem */
  }

  .d-content-description-text {
    color: var(--Neutral-500, #717286);

    /* Paragraph 03/P1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.05rem */
  }

  .d-content-price-indicator {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
  }

  .d-content-price-indicator-header {
    display: flex;
    align-items: center;
  }

  .d-price-indicator-header-text-gain {
    color: var(--Success-700, #38602b);

    /* Paragraph 02/P3 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.3125rem */
  }

  .d-price-indicator-header-text-dip {
    color: var(--Error-700, #bc1919);

    /* Paragraph 02/P3 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.3125rem */
  }

  .d-price-indicator-fotter-text {
    color: var(--Neutral-400, #8f91a1);

    /* Paragraph 03/P1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.05rem */
  }

  .startup {
    background: linear-gradient(180deg, #643fff 0%, #381ab7 100%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 20px;
  }

  .startup-icon-container {
    padding-top: 50px;
    padding-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
  }

  .startup-icon-shadow {
    position: absolute;
    top: 413px;
    left: 83px;
  }

  .startup-text-container {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 56px;
  }

  .startup-text-header {
    color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
    font-family: Manrope;
    font-size: 2.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.8125rem; /* 140.625% */
    width: 335px;
  }

  .startup-text-footer {
    color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
    font-family: Roboto;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 171.429% */
    opacity: 0.8;
    width: 335px;
  }

  .startup-language {
    background: var(--common-white_states-main, #fff);
    height: 100%;
    width: 100%;
    padding: 34px 20px 0 20px;
  }

  .startup-language-content-container {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    margin-bottom: 120px;
  }

  .st-language-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    width: 100%;
  }

  .st-language-text {
    color: var(--Neutral-950, #0c0c0e);

    /* Heading/H2 */
    font-family: Roboto;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 1.5rem */
    align-self: stretch;
  }

  .startup-contact {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    /* margin-bottom: 400px; */
  }

  .startup-contact-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }

  .startup-contact-text {
    color: var(--Neutral-950, #0c0c0e);

    /* Heading/H2 */
    font-family: Roboto;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 1.5rem */
    align-self: stretch;
  }

  .st-contact-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
  }

  .st-contact-content-container {
    display: flex;
    align-items: flex-start;
    gap: var(--space-8px, 8px);
    width: 100%;
  }

  .st-contact-first-section {
    display: flex;
    width: 34.9253%;
    padding: 12px;
    align-items: center;
    gap: 6px;
    border-radius: var(--space-8px, 8px);
    background: var(--Neutral-50, #f7f7f8);
    cursor: pointer;
    user-select: none;
  }

  .st-contact-selection-icon {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 24px;
    cursor: pointer;
    user-select: none;
  }

  .st-contact-selection-text {
    color: var(--Neutral-950, #0c0c0e);
    text-align: right;

    /* Paragraph 02/P2 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.225rem */
    width: 33px;
    flex-shrink: 0;
    cursor: pointer;
    user-select: none;
  }

  .st-contact-dropdown {
    cursor: pointer;
    user-select: none;
  }

  .st-contact-second-section {
    display: flex;
    width: 65.0746%;
    height: 48px;
    padding: 12px;
    align-items: center;
    gap: 6px;
    border-radius: var(--space-8px, 8px);
    background: var(--Neutral-50, #f7f7f8);
  }

  .st-contact-second-section::placeholder {
    color: var(--Neutral-300, #b6b7c3);

    /* Paragraph 02/P1 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.3125rem */
  }

  .startup-contact-footer {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
  }

  .st-contact-footer-first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
  }

  .st-contact-footer-second-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
  }

  .custom-blank-page {
    margin-bottom: 420px;
  }

  .st-contact-input-error {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .input-error-text {
    color: var(--Error-600, #e23636);

    /* Paragraph 03/P1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.05rem */
  }

  .startup-otp-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  .startup-otp-header-text {
    align-self: stretch;
    color: var(--Neutral-600, #5b5d6e);

    /* Paragraph 01/P1 */
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.5rem */
  }

  .startup-otp-footer {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .startup-otp-footer-text {
    color: var(--Neutral-950, #0c0c0e);

    /* Sub Heading/SH1 */
    font-family: Manrope;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 1rem */
  }

  .otp-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
  }

  .asset-layout-content {
    display: inline-flex;
    padding: 0 20px 155px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 8px;
    background: var(--Neutral-White-100, #fff);
    width: 100%;
    height: 100%;
  }

  .asset-content-first-container {
    display: flex;
    padding: 0px 20px;
    align-items: center;
    gap: 18px;
    border-bottom: 1px solid var(--Neutral-50, #f7f7f8);
    justify-content: center;
    width: 100%;
  }

  .asset-content-first-content {
    display: flex;
    padding-top: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }

  .asset-content-first-content-text-active {
    color: var(--secondary-electric-violet-800, #3f25ae);
    text-align: center;

    /* Paragraph 02/P1 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.3125rem */
    width: 70px;
  }

  .asset-content-first-content-text {
    width: 70px;
    color: var(--Neutral-500, #717286);
    text-align: center;

    /* Paragraph 02/P1 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.3125rem */
  }

  .asset-content-first-content-line-active {
    height: 3px;
    align-self: stretch;
    border-radius: 2px;
    background: var(--secondary-electric-violet-800, #3f25ae);
  }

  .asset-content-first-content-line {
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 2px;
    background: transparent;
  }
  .general-page-third-content-outter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
  }

  .gp-main-content-footer-content-outter {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Neutral-100, #eeeef0);
    background: var(--Neutral-White-100, #fff);
    width: 100%;
  }

  .gp-main-content-footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .gp-main-content-footer-content-first-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }

  .gp-main-content-footer-content-first-section-title {
    color: var(--Neutral-950, #0c0c0e);

    /* Paragraph 02/P2 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.225rem */
  }

  .gp-main-content-footer-content-first-section-label {
    color: var(--Neutral-500, #717286);

    /* Paragraph 03/P1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.05rem */
  }

  .gp-main-content-footer-content-second-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
  }

  .gp-main-content-footer-content-second-section-title-container {
    display: flex;
    align-items: center;
  }

  .gp-main-content-footer-content-first-section-title-gain {
    color: var(--Success-700, #38602b);

    /* Paragraph 02/P3 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.3125rem */
  }

  .gp-main-content-footer-content-first-section-title-loss {
    color: var(--Error-700, #bc1919);

    /* Paragraph 02/P3 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.3125rem */
  }

  .gp-main-content-footer-content-second-section-label {
    color: var(--Neutral-400, #8f91a1);

    /* Paragraph 03/P1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.05rem */
  }

  .gp-investment-gen-div {
    display: inherit;
    gap: inherit;
    flex-direction: inherit;
    overflow: auto;
    width: 100%;
    scrollbar-width: none;
  }

  .portfolio-page-content-cont {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Neutral-White-100, #fff);

    /* Shadow Nav 1 */
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  }

  .portfolio-page-content-first-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }

  .portfolio-page-content-first-section-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .portfolio-page-content-first-section-title {
    color: var(--Neutral-600, #5b5d6e);

    /* Paragraph 03/P1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.05rem */
  }

  .portfolio-page-content-first-section-label-cont {
    display: flex;
    align-items: center;
  }

  .portfolio-page-content-first-section-label-gain {
    color: var(--Success-600, #41732f);

    /* Sub Heading/SH2 */
    font-family: Manrope;
    font-size: 1rem;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 1rem */
  }

  .portfolio-page-content-first-section-label-loss {
    color: var(--Error-700, #bc1919);

    /* Sub Heading/SH2 */
    font-family: Manrope;
    font-size: 1rem;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 1rem */
  }

  .portfolio-page-content-first-section-button {
    display: flex;
    padding: 6px 12px;
    align-items: center;
    gap: 4px;
    border-radius: 32px;
    background: var(--secondary-electric-violet-900, #362689);
  }

  .portfolio-page-content-first-section-button-text {
    color: var(--Neutral-White-100, #fff);

    /* Paragraph 03/P1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.05rem */
  }

  .portfolio-page-content-second-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  .portfolio-page-content-second-section-container {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  .portfolio-page-content-second-section-content {
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 6px;
    border: 1px solid var(--Neutral-200, #d8d9df);
  }

  .portfolio-page-content-second-section-content-title {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .portfolio-page-content-second-section-content-text {
    color: var(--Neutral-500, #717286);

    /* Paragraph 03/P1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.05rem */
    width: 124px;
  }

  .portfolio-page-content-second-section-content-label-cont {
    display: flex;
    align-items: center;
  }

  .portfolio-page-content-second-section-content-label-gain {
    color: var(--Success-700, #38602b);

    /* Paragraph 02/P2 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.225rem */
  }

  .portfolio-page-content-second-section-content-label-loss {
    color: var(--Error-700, #bc1919);

    /* Paragraph 02/P2 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.225rem */
  }

  .gp-portfolio-holding-content {
    display: flex;
    width: 100%;
    padding: 12px;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid var(--Neutral-100, #eeeef0);
    background: var(--Neutral-White-100, #fff);
  }

  .gp-portfolio-holding-content-first-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }

  .gp-portfolio-holding-content-first-section-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }

  .gp-portfolio-holding-content-first-section-header-cont {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .gp-portfolio-holding-content-first-section-header-title {
    color: var(--Neutral-950, #0c0c0e);
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .gp-portfolio-holding-content-first-section-header-label {
    color: var(--Neutral-500, #717286);

    /* Paragraph 03/P1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.05rem */
  }

  .gp-portfolio-holding-content-first-section-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 6px;
  }

  .gp-portfolio-holding-content-first-section-footer-cont {
    display: flex;
    align-items: center;
  }

  .gp-portfolio-holding-content-first-section-footer-title-gain {
    color: var(--Success-600, #41732f);

    /* Paragraph 02/P3 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.3125rem */
  }

  .gp-portfolio-holding-content-first-section-footer-title-loss {
    color: var(--Error-700, #bc1919);

    /* Paragraph 02/P3 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.3125rem */
  }

  .gp-portfolio-holding-content-first-section-footer-label-cont {
    display: flex;
    align-items: flex-start;
    gap: 4px;
  }

  .gp-portfolio-holding-content-first-section-footer-label {
    color: var(--Neutral-400, #8f91a1);

    /* Paragraph 03/P2 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.05rem */
  }

  .gp-portfolio-holding-content-first-section-footer-label-text {
    color: var(--Neutral-700, #4a4a5a);

    /* Paragraph 03/P2 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.05rem */
  }

  .holding-content-first-section-footer-label-text-gain {
    color: var(--Success-600, #41732f);

    /* Paragraph 03/P2 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .holding-content-first-section-footer-label-text-loss {
    color: var(--Error-700, #bc1919);

    /* Paragraph 03/P2 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .gp-portfolio-holding-content-second-section {
    display: flex;
    padding: 6px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: var(--Neutral-50, #f7f7f8);
  }

  .gp-portfolio-holding-content-second-section-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .gp-portfolio-holding-content-second-section-content-title {
    color: var(--Neutral-700, #4a4a5a);

    /* Paragraph 04/P1 */
    font-family: Roboto;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 0.875rem */
    width: 98px;
  }

  .gp-portfolio-holding-content-second-section-content-label {
    color: var(--Neutral-800, #40414c);

    /* Paragraph 03/P2 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.05rem */
    width: 98px;
  }

  .general-page-fourth-content-container {
    display: flex;
    flex-direction: inherit;
    width: 100%;
    gap: 15px;
  }

  .portfolio-page-actions-content-header-last-container {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
    border-radius: 6px;
    background: var(--Neutral-50, #f7f7f8);
    width: 100%;
  }

  .portfolio-page-actions-content-header-last-label {
    color: var(--Neutral-600, #5b5d6e);

    /* Paragraph 03/P1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.05rem */
    align-self: stretch;
    word-wrap: break-word;
    white-space: normal;
    width: 100%;
  }

  .portfolio-page-actions-content-header-last {
    width: 100%;
  }

  .gp-orders-page-table-container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .gp-orders-page-header {
    display: flex;
    width: 100%;
    padding: 4px 20px;
    justify-content: space-between;
    align-items: flex-end;
    background: var(--Neutral-50, #f7f7f8);
  }

  .gp-orders-page-header-content {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .gp-orders-page-header-title {
    color: var(--Neutral-400, #8f91a1);

    /* Paragraph 03/P1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.05rem */
  }

  .gp-orders-page-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
  }

  .gp-orders-page-body-header-first {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .gp-orders-page-body-header-first-title-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }

  .gp-orders-page-body-header-first-title {
    color: var(--Neutral-950, #0c0c0e);
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .gp-orders-page-body-header-first-body {
    display: flex;
    align-items: flex-start;
    gap: 5px;
  }

  .gp-orders-page-body-header-first-body-sell {
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 2px;
    background: var(--Error-100, #fee2e2);
  }

  .gp-orders-page-body-header-first-body-buy {
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 2px;
    background: var(--Success-100, #e0f0d7);
  }

  .gp-orders-page-body-header-first-body-text-buy {
    color: var(--Success-600, #41732f);

    /* Paragraph 04/P1 */
    font-family: Roboto;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 0.875rem */
  }

  .gp-orders-page-body-header-first-body-text-sell {
    color: var(--Error-800, #9b1919);

    /* Paragraph 04/P1 */
    font-family: Roboto;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 0.875rem */
  }

  .gp-orders-page-body-header-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 6px;
    align-self: stretch;
  }

  .gp-orders-page-body-header-second-title {
    color: var(--Neutral-700, #4a4a5a);

    /* Paragraph 04/P1 */
    font-family: Roboto;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 0.875rem */
  }

  .gp-orders-page-body-header-second-label {
    color: var(--Neutral-700, #4a4a5a);
    text-align: right;

    /* Paragraph 03/P2 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.05rem */
  }

  .gp-orders-page-body-header-second-cont {
    display: flex;
    padding: 6px 8px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    background: var(--Neutral-50, #f7f7f8);
  }

  .gp-orders-page-body-header-second-cont-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .gp-orders-page-body-header-second-cont-title {
    color: var(--Neutral-700, #4a4a5a);

    /* Paragraph 04/P1 */
    font-family: Roboto;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 0.875rem */
    width: 90px;
  }

  .gp-orders-page-body-header-second-cont-label {
    color: var(--Neutral-800, #40414c);

    /* Paragraph 03/P2 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.05rem */
  }

  .gp-orders-page-body-header-second-cont-content-last {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
  }

  .gp-orders-page-body-header-second-cont-label-gain {
    color: var(--Success-700, #38602b);

    /* Paragraph 03/P3 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 1.05rem */
  }

  .gp-orders-page-body-cont {
    display: flex;
    width: 100%;
    padding: 12px;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid var(--Neutral-100, #eeeef0);
    background: var(--Neutral-White-100, #fff);
  }

  .orders-page-content-header-container-button-group {
    width: 100%;
  }

  .orders-page-content-header-container-buttons {
    width: 100%;
  }

  .gp-mobile-kyc {
    display: flex;
    width: 100%;
    padding: 50px 20px 84px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 135px;
    background: var(--Neutral-White-100, #fff);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }

  .gp-mobile-kyc-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .gp-mobile-kyc-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .gp-mobile-kyc-content-title {
    color: var(--Neutral-950, #0c0c0e);
    text-align: center;
    font-family: Manrope;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 800;
    line-height: 140%; /* 2.1rem */
  }

  .gp-mobile-kyc-content-label {
    color: var(--Neutral-600, #5b5d6e);
    text-align: center;

    /* Paragraph 01/P1 */
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.5rem */
  }

  .mobile-add-funds-content {
    display: inline-flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    background: var(--Neutral-White-100, #fff);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    top: -56px;
    z-index: 2;
    margin-right: 22px;
    overflow: auto;
  }

  .mobile-add-funds-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
  }

  .mobile-add-funds-content-title {
    width: 307px;
    color: var(--Neutral-950, #0c0c0e);

    /* Paragraph 02/P2 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.225rem */
  }

  .mobile-add-funds-currencies-content {
    display: inline-flex;

    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Neutral-White-100, #fff);

    /* Shadow Nav 1 */
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    top: -56px;
    z-index: 2;
    overflow-y: auto;
  }

  .mobile-add-funds-content-title-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
  }
  .add-funds-content-search-result {
    overflow-y: auto;
  }
}

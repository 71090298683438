.common-footer {
  display: flex;
  position: fixed;
  bottom: 0;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  align-self: stretch;
  background: var(--Neutral-50, #f7f7f8);
  width: 100%;
  z-index: 3;
  height: 84px;
  max-height: 84px;
}

.footer-menu {
  display: flex;
  padding: 4px var(--space-0, 0px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: fit-content;
}

.footer-menu-icon-container {
  display: flex;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
}
.footer-menu-icon-container-active {
  display: flex;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: var(--primary-bitter-lemon-500, #cfe600);
}

.footer-menu-icon {
  display: flex;
  /* width: 24px; */
  /* height: 24px; */
  padding: 3px 4.44px 3.96px 4px;
  justify-content: center;
  align-items: center;
}

.fotter-menu-text {
  display: flex;
  width: 80px;
  height: 18px;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--Neutral-300, #b6b7c3);
  text-align: center;

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  opacity: 0.8;
}

.fotter-menu-text-active {
  display: flex;
  width: 80px;
  height: 18px;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  opacity: 0.8;
}

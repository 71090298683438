.layout-body {
  display: flex;
  /* width: 96.25%; */
  height: 91vh;
  justify-content: space-between;
  align-items: flex-start;
  margin: 24px 24px 0px 24px;
  gap: 20px;
  overflow: hidden;
}

.layout-content {
  display: flex;
  /* width: 84%; */
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  padding-bottom: 15px;
  /* position: relative; */
  flex: 1;
  overflow: auto;
}

.side-bar {
  display: flex;
  height: 656px;
  padding: 32px 14px 14px 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
  position: sticky;
  top: 0;
}

.s-menu {
  display: flex;
  padding: var(--space-8px, 8px);
  width: 100%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
}

.menu-toggle {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 7.748px;
  justify-content: center;
  align-items: center;
  gap: 9.685px;
  flex-shrink: 0;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);
  box-shadow: 1.937px 1.937px 19px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 12px;
  /* left: 92%; */
  right: -12px;
  cursor: pointer;
  user-select: none;
}

.menu-toggle-icon {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}

.s-menu-active {
  display: flex;
  padding: var(--space-8px, 8px);
  width: 100%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  background: var(--primary-bitter-lemon-500, #cfe600);
  min-width: 40px;
}

.s-menu-active:hover {
  opacity: 0.5;
  background: var(--primary-bitter-lemon-500, #cfe600);
}

.s-menu:hover {
  background-color: transparent;
}

.s-menu-container {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
  width: 100%;
}

.sidebar-dropdown-icon {
  position: absolute;
  right: 22px;
}

.s-menu-icon {
  display: flex;
  padding: 3px 2px 3.96px 2px;
  justify-content: center;
  align-items: center;
}

.s-menu-text-container {
  display: flex;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

.s-menu-text {
  color: var(--Neutral-300, #b6b7c3);
  min-width: 88px;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  font-family: Roboto;
  line-height: 1.25rem;
  /* 142.857% */
}

.s-menu-toggle {
  width: 14px;
  height: 14px;
}

.s-menu-text-active {
  color: var(--Primary-03-Main, #1e2528);
  min-width: 88px;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  font-family: Roboto;
  line-height: 1.25rem;
  /* 142.857% */
}

.s-menu-dropdown-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;
  border-radius: var(--space-8px, 8px);
  border: 1px solid transparent;
}

.s-menu-dropdown-container-active {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;
  border-radius: var(--space-8px, 8px);
  border: 1px solid transparent;
}

.s-menu-dropdown-container:hover {
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-50, #ecefff);
}

/* .s-menu-dropdown-container-active:hover {
    border-radius: var(--space-8px, 8px);
    opacity: 0.5;
    background: var(--primary-bitter-lemon-500, #CFE600);
} */

.s-menu-dropdown {
  display: flex;
  height: 32px;
  padding-left: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
}

.s-menu-dropdown-active {
  display: flex;
  height: 32px;
  padding-left: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--primary-bitter-lemon-500, #cfe600);
}

.s-menu-dropdown-text-container {
  display: flex;
  height: 30px;
  padding: 4px 16px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
}

.s-menu-dropdown-left-content {
  display: flex;
  width: 56px;
  height: 24px;
  min-width: 56px;
  flex-direction: column;
  align-items: flex-start;
}

.s-menu-dropdown-text-content {
  align-self: stretch;
  display: flex;
  padding: var(--space-0, 0px);
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.s-menu-dropdown-text {
  align-self: stretch;
  color: var(--Neutral-950, #0c0c0e);
  font-feature-settings: "liga" off, "clig" off;
  width: 100%;
  font-size: 0.75rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  /* 166.667% */
}

.l-search {
  display: flex;
  width: 100%;
  height: 48px;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
}

.l-search-right {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
}

.l-search-left {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.l-search-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  user-select: none;
}

.l-search-input {
  display: flex;
  align-items: center;
  gap: 2px;
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  background: transparent;
  width: 100%;
}

.l-search-input::placeholder {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.l-search-separator {
  width: 1px;
  height: 24px;
  background: #d8d9df;
}

.l-search-widget-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  user-select: none;
}

@media screen and (min-width: 300px) and (max-width: 1200px) {
  .layout-content {
    background: var(--common-white_states-main, #fff);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5.3012%;
    padding-bottom: 5.3012%;
    width: 100%;
    height: 58.7327376%;
    overflow: auto;
    padding-bottom: 84px;
    scrollbar-width: none;
  }
}

/* @media screen and (min-width: 1600px) and (max-width: 8000px){
    .layout-content {
        display: flex;
        width: 88%;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex-shrink: 0;
        padding-bottom: 15px;
        position: relative;
    }
} */

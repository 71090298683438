.App {
  width: 100%;
  height: 100vh;
  flex-shrink: 0;
  background: var(--common-white_states-main, #fff);
}

textarea:focus,
input:focus {
  outline: none;
}

input {
  -moz-appearance: textfield;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

@media screen and (min-width: 300px) and (max-width: 1200px) {
  .App {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    background: var(--secondary-electric-violet-800, #3f25ae);
    scrollbar-width: none;
    overflow: hidden;
  }
}

textarea:focus,
input:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  /* display: none; */
}

::-webkit-scrollbar-thumb {
  height: 4px;
  width: 5px;
  background-color: var(--Neutral-200, #d8d9df);
  border-radius: 4px;
  /* display: none; */
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--Neutral-200, #d8d9df);
  /* display: none; */
}

::-webkit-scrollbar-track {
  background: transparent;
  height: 4px;
  width: 5px;
  cursor: pointer;
  /* display: none; */
}

@import url(./styles/sidebar.css);
@import url(./styles/page.css);
@import url(./styles/overlay.css);
@import url(./styles/header.css);
@import url(./styles/footer.css);

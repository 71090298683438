.general-overlay {
  display: flex;
  padding: 10px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-50, #ecefff);
}

.overlay-content {
  display: flex;
  align-items: flex-start;
}

.overlay-content-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Caption/C1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 1.125rem */
}

.overlay-button {
  display: flex;
  width: 100px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-600, #603ff4);
  cursor: pointer;
  user-select: none;
}

.overlay-button-text {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  text-align: center;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  /* 142.857% */
}

.assets-overlay {
  display: flex;
  width: 500px;
  flex-direction: row;
  align-items: flex-start;
  border-radius: var(--space-8px, 8px);
  background: linear-gradient(75deg, #f9ffc4 0%, #efff59 99.4%);
  overflow: hidden;
}

.assets-overlay-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin: 15px 0 15px 15px;
}

.assets-overlay-text-header {
  width: 210px;
  margin-right: 35px;
}

.assets-overlay-text-footer {
  color: var(--Neutral-700, #4a4a5a);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 1.05rem */
  margin-bottom: 20px;
}

.assets-overlay-button {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--secondary-electric-violet-600, #603ff4);
  cursor: pointer;
  user-select: none;
}

overlay-button-text {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  text-align: center;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  /* 142.857% */
}

.assets-overlay-image {
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0;
  width: 220px;
  height: 175px;
  /* background-color: red; */
}

.auto-slider {
  display: flex;
  width: 85px;
  height: 4px;
  padding-right: 60px;
  align-items: center;
  border-radius: var(--space-8px, 8px);
  background: var(--action-focus, rgba(0, 0, 0, 0.12));
}

.auto-slider-line {
  width: 25px;
  height: 4px;
  flex-shrink: 0;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-500, #6756fe);
}

.terms-and-condition {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  /* width: 335px; */
}

.privacy {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 20px;
}

.privacy-text {
  color: var(--Neutral-800, #40414c);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 166.667% */
  letter-spacing: 0.01563rem;
}

.l-other-overlay {
  display: flex;
  padding: 12px var(--space-0, 0px);
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-bottom: 1px solid var(--secondary-electric-violet-50, #ecefff);
}

.verify-kyc-popup {
  display: inline-flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);
  margin-top: 25px;
}

.verify-kyc-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.verify-kyc-header-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;
  font-family: Manrope;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 2.1rem */
}

.verify-kyc-footer-text {
  color: var(--Neutral-600, #5b5d6e);
  text-align: center;

  /* Paragraph 01/P1 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  width: 347px;
}

.verify-kyc-buttons {
  display: flex;
  width: 394px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 50px;
}

.verify-kyc-button-back {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-950, #0c0c0e);
  cursor: pointer;
  user-select: none;
}

.verify-kyc-button {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  background: var(--primary-bitter-lemon-500, #cfe600);
  cursor: pointer;
  user-select: none;
}

.verify-kyc-button-back-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.general-web-toast-error {
  display: flex;
  align-items: center;
  gap: 12px;
}

.general-web-toast-error-container {
  display: flex;
  width: 68.7%;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.general-web-toast-error-title {
  color: var(--Neutral-950, #0c0c0e);

  /* Sub Heading/SH1 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 1rem */
  align-self: stretch;
}

.general-web-toast-error-label {
  align-self: stretch;
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.unsupported-network-popup {
  display: flex;
  width: 379px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: #fff;
}

.unsupported-network-popup-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.unsupported-network-popup-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  position: relative;
}

.unsupported-network-popup-first-title {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Manrope;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.875rem */
  width: 335px;
}

.unsupported-network-popup-first-label {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  align-self: stretch;
}

.unsupported-network-popup-first-img {
  display: flex;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: -4px;
  top: -4px;
  border-radius: 100px;
}

.unsupported-network-popup-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.unsupported-network-popup-second-title {
  width: 264px;
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
}

.unsupported-network-popup-second-label {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.switch-network-popup {
  display: flex;
  width: 379px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background: #fff;
  margin-top: 100px;
}

.switch-network-popup-first-section-cont {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  width: 100%;
}

.switch-network-popup-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.switch-network-popup-first-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.switch-network-popup-first-section-title {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Manrope;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.875rem */
  width: 335px;
}

.switch-network-popup-first-section-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.switch-network-popup-first-section-content-cont {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
}

.switch-network-popup-first-section-content-cont-active {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: var(--secondary-electric-violet-50, #ecefff);
  cursor: pointer;
}

.switch-network-popup-first-section-content-cont:hover {
  border-radius: 4px;
  background: var(--secondary-electric-violet-50, #ecefff);
}

.switch-network-popup-first-section-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.switch-network-popup-first-section-cont-text {
  color: var(--Neutral-900, #383842);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.switch-network-popup-line {
  width: 347px;
  height: 1px;
  background: #eeeef0;
}

.switch-network-popup-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.switch-network-popup-second-title {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  align-self: stretch;
}

.switch-network-popup-second-text-cont {
  display: flex;
  align-items: center;
  gap: 4px;
}

.switch-network-popup-second-text {
  color: var(--secondary-electric-violet-700, #4e2bd7);

  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
  width: 264px;
}

.asset-info-popup-third {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.asset-info-popup-third-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.asset-info-popup-third-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.asset-info-popup-third-last {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.asset-info-popup-third-first-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.asset-info-popup-third-first-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.asset-info-popup-third-first-header-title {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.asset-info-popup-third-first-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.asset-info-popup-third-first-body-line {
  width: 100%;
  height: 1px;
  background: #eeeef0;
}

.asset-info-popup-third-first-footer-text {
  align-self: stretch;
  color: var(--Neutral-500, #717286);
  overflow: hidden;
  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  max-height: 6em;
}

.asset-info-popup-third-first-body-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.asset-info-popup-third-first-body-text-cont {
  display: flex;
  align-items: center;
  gap: 8px;
}

.asset-info-popup-third-first-body-text {
  color: var(--Neutral-900, #383842);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.asset-info-popup-third-first-body-label {
  color: var(--Neutral-700, #4a4a5a);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.asset-info-popup-third-first-footer-container {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: #fff;
}

.asset-info-popup-third-first-footer-title {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.asset-info-popup-third-first-footer-body-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
}

.asset-info-popup-third-first-footer-body-pointer-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: -6px;
  border-radius: 2px;
  position: absolute;
  bottom: 1px;
}

.asset-info-popup-third-first-footer-body {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  position: relative;
  padding-top: 20px;
}

.asset-info-popup-third-first-footer-body-pointer {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--secondary-electric-violet-50, #ecefff);
}

.asset-info-popup-third-first-footer-body-pointer-text {
  color: var(--Neutral-900, #383842);
  text-align: center;

  /* Paragraph 04/P2 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 0.875rem */
}

.asset-info-popup-third-first-footer-body-price-cont {
  width: 100%;
  height: 9px;
  flex-shrink: 0;
  cursor: pointer;
}

.asset-info-popup-third-first-footer-body-price-line {
  width: 100%;
  height: 5px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--Neutral-100, #eeeef0);
  position: relative;
}

.asset-info-popup-third-first-footer-body-price-mark-start {
  width: 4px;
  height: 9px;
  flex-shrink: 0;
  border-radius: 1px;
  background: var(--Neutral-700, #4a4a5a);
  position: absolute;
  top: -2px;
  left: 0;
}

.asset-info-popup-third-first-footer-body-price-mark-end {
  width: 4px;
  height: 9px;
  flex-shrink: 0;
  border-radius: 1px;
  background: var(--Neutral-700, #4a4a5a);
  position: absolute;
  top: -2px;
  right: 0;
}

.asset-info-popup-third-first-footer-body-price-ind {
  position: absolute;
  top: 0;
}

.asset-info-popup-third-first-footer-body-price-label {
  display: flex;
  padding-top: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.asset-info-popup-third-first-footer-body-price-low {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.asset-info-popup-third-first-footer-body-price-low-title {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
}

.asset-info-popup-third-first-footer-body-price-low-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
}

.asset-info-popup-third-first-footer-body-price-high {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 2px;
}

.asset-info-popup-third-first-footer-last {
  display: flex;
  padding: 12px 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Error-50, #fef2f2);
}

.asset-info-popup-third-first-footer-last-text {
  flex: 1 0 0;
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.asset-info-popup-third-first-footer-last-text-label {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.asset-info-popup-third-second-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.asset-info-popup-third-second-body {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--Neutral-White-100, #fff);
}

.asset-info-popup-third-second-body-cont {
  display: flex;
  align-items: flex-start;
  gap: 40px;

  width: 100%;
}

.asset-info-popup-third-second-body-content {
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 25%;
}

.asset-info-popup-third-second-body-content-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
}

.asset-info-popup-third-second-body-content-body-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.asset-info-popup-third-second-body-content-body-text {
  color: var(--Neutral-700, #4a4a5a);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
  width: 90px;
}

.asset-info-popup-third-second-body-content-label {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  width: 90px;
}

.asset-info-popup-third-last {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.asset-info-popup-third-last-content-outer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  cursor: pointer;
}

.asset-info-popup-third-last-content {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Neutral-White-100, #fff);
  box-shadow: 2px 2px 12px 0px rgba(122, 122, 122, 0.12);
}

.asset-info-popup-third-last-content-text-cont {
  display: flex;
  align-items: center;
  gap: 8px;
}

.asset-info-popup-third-last-content-text {
  color: var(--Neutral-900, #383842);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.profile-popup-content-third-button {
  display: flex;
  width: 100px;
  padding: 6px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid var(--secondary-electric-violet-700, #4e2bd7);
}

.profile-popup-content-third-button-text {
  color: var(--secondary-electric-violet-600, #603ff4);
  text-align: center;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 1.09375rem */
}

.unsupported-network-popup-dropdown-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  position: relative;
}

.unsupported-network-popup-dropdown {
  display: flex;
  height: 45px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-200, #d8d9df);
}

.unsupported-network-popup-dropdown-text-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.unsupported-network-popup-dropdown-text {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.general-web-toast-success {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Success-50, #f1f8ed);
  width: 100%;
  height: 55px;
  max-height: 55px;
}

.general-web-toast-success-cont {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.general-web-toast-success-container {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.general-web-toast-success-link {
  display: flex;
  align-items: center;
}

.general-web-toast-success-link-text {
  color: var(--secondary-electric-violet-600, #603ff4);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.verify-kyc-button-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.add-funds-popup {
  display: flex;
  width: 28.4375%;
  height: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  background: var(--common-white_states-main, #fff);
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.12);
  overflow-x: hidden;
  overflow-y: auto;
}

.add-funds-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.add-funds-content-header {
  display: flex;
  height: 30px;
  align-items: center;
  gap: 156px;
  align-self: stretch;
  position: relative;
}

.add-funds-content-header-text-container {
  display: flex;
  align-items: center;
  gap: 2px;
  position: relative;
}

.add-funds-content-header-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.2rem */
}

.add-funds-content-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.add-funds-content-footer-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  width: 307px;
}

.a-f-content-footer-input-container {
  display: flex;
  padding: var(--space-8px, 8px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-200, #d8d9df);
  background: var(--Neutral-50, #f7f7f8);
}

.a-f-content-footer-input {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  background: transparent;
  margin-left: 4px;
  /* border-left: 1px solid blue; */
  color: var(--Neutral-950, #0c0c0e);
  width: 100%;

  /* Heading/H4 */
  font-family: Manrope;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.5rem */
}

.a-f-content-footer-input::placeholder {
  color: var(--Neutral-300, #b6b7c3);

  /* Heading/H4 */
  font-family: Manrope;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.a-f-content-footer-input-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Heading/H4 */
  font-family: Manrope;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.5rem */
}

.a-f-content-footer-currencies {
  display: flex;
  padding: 6px var(--space-8px, 8px);
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: var(--secondary-electric-violet-100, #dde1ff);
  cursor: pointer;
  user-select: none;
}
.a-f-content-footer-currencies-slip {
  display: flex;
  padding: 6px var(--space-8px, 8px);
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: var(--secondary-electric-violet-100, #dde1ff);
  cursor: pointer;
  user-select: none;
}

.a-f-content-footer-currencies-slip:hover {
  border: inherit;
  border-radius: 4px;
  background: var(--secondary-electric-violet-100, #a7b0fb);
}

.a-f-content-footer-currencies-container {
  display: flex;
  align-items: center;
  gap: 2px;
}

.a-f-content-footer-currency {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.add-funds-popup-close-icon {
  position: absolute;
  right: 0px;
  bottom: 0.5px;
  cursor: pointer;
}

.a-f-content-footer-input-group {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.add-funds-content-search {
  display: flex;
  height: 48px;
  padding: 12px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Neutral-200, #d8d9df);
  background: var(--Neutral-50, #f7f7f8);
}

.add-funds-content-search-input {
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;
  background: transparent;
  color: var(--Neutral-950, #0c0c0e);
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.2rem */
}

.add-funds-content-search-input::placeholder {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.add-funds-search-header-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.2rem */
}

.add-funds-content-search-result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.a-f-content-search-result-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.a-f-content-search-result-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.a-f-content-search-header-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.a-f-content-search-header-main {
  display: flex;
  padding: var(--space-8px, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  cursor: pointer;
  user-select: none;
}

.a-f-content-search-header-main:hover {
  border-radius: 6px;
  border: 1px solid;
}

.a-f-content-search-header-main-active {
  display: flex;
  padding: var(--space-8px, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  cursor: pointer;
  user-select: none;
  border-radius: 6px;
  background: var(--Success-50, #f1f8ed);
}

.a-f-content-search-header-main-active:hover {
  border-radius: 6px;
  background: var(--Success-50, #f1f8ed);
}

.a-f-content-search-header-main-container {
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
}

.a-f-content-search-header-main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.a-f-content-search-header-image {
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Neutral-200, #d8d9df);
  overflow: hidden;
}

.a-f-content-search-header-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.a-f-content-search-header-text-header {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.a-f-content-search-header-text-footer {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.add-funds-popup-back-icon {
  margin-right: 8px;
}

.payments-popup-content-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.payments-popup-content-footer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.payments-popup-content-footer-main {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-200, #d8d9df);
  cursor: pointer;
  user-select: none;
}

.payments-popup-content-footer-main:hover {
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--secondary-electric-violet-300, #9da3ff);
  background: var(--secondary-electric-violet-50, #ecefff);
}

.payments-popup-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.payments-popup-content-icon {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 2px;
  justify-content: center;
  align-items: center;
}

.payments-popup-content-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.p-p-payment-portal-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.p-p-payment-portal-amount-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.p-p-payment-portal-contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.p-p-payment-portal-amount-info-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.p-p-payment-portal-amount-info-separator {
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  justify-content: center;
}

.p-p-payment-portal-amount-info-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.p-p-payment-portal-header-text-container {
  display: flex;
  padding: 12px var(--space-0, 0px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.p-p-payment-portal-header-button {
  display: flex;
  padding: 12px 110px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: #1077d1;
}

.p-p-payment-portal-header-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 01/P1 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  width: 335px;
}

.p-p-payment-portal-header-text-footer {
  color: var(--Neutral-950, #0c0c0e);

  /* Heading/H1 */
  font-family: Manrope;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%; /* 2.625rem */
  letter-spacing: 0.035rem;
}

.p-p-payment-portal-header-button-text {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  text-align: center;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.p-payment-portal-separator-text {
  color: var(--Neutral-700, #4a4a5a);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  opacity: 0.6;
}

.p-p-payment-portal-line {
  width: 35%;
  height: 1px;
  background: #eeeef0;
}

.p-p-payment-portal-amount-footer-main {
  display: flex;
  padding: var(--space-8px, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  border-radius: 4px;
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--common-white_states-main, #fff);
  width: 24%;
  cursor: pointer;
  user-select: none;
}

.p-p-payment-portal-amount-footer-main-active {
  display: flex;
  padding: var(--space-8px, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);

  width: 24%;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  border: 1.5px solid var(--Success-500, #5d9e44);
  background: var(--common-white_states-main, #fff);
}

.p-p-payment-portal-amount-footer-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  width: 60px;
}

.p-p-payment-portal-contact-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.p-p-payment-portal-email-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.p-p-payment-portal-card-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.p-p-payment-portal-card-holder-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.p-p-payment-portal-email-text {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.p-p-payment-portal-input {
  display: flex;
  padding: 13.5px 12px;
  align-items: center;
  gap: 177px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-200, #d8d9df);
  background: var(--common-white_states-main, #fff);
}

.p-p-payment-portal-input::placeholder {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.p-p-payment-portal-card-info-input {
  display: flex;
  padding: 13.5px 12px;
  align-items: center;
  gap: 177px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px) var(--space-8px, 8px) 0px 0px;
  border: 1px solid var(--Neutral-200, #d8d9df);
  background: var(--common-white_states-main, #fff);
}

.p-p-payment-portal-card-info::placeholder {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.p-p-payment-portal-card-info-container {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.p-p-payment-portal-card-info-input-1 {
  display: flex;
  padding: 13.5px 12px;
  align-items: center;
  gap: 177px;
  flex: 1 0 0;
  border-radius: 0px 0px 0px var(--space-8px, 8px);
  border-bottom: 1px solid var(--Neutral-200, #d8d9df);
  border-left: 1px solid var(--Neutral-200, #d8d9df);
  background: var(--common-white_states-main, #fff);
}

.p-p-payment-portal-card-info-input-2 {
  display: flex;
  padding: 13.5px 12px;
  align-items: center;
  gap: 177px;
  flex: 1 0 0;
  border-radius: 0px 0px var(--space-8px, 8px) 0px;
  border-right: 1px solid var(--Neutral-200, #d8d9df);
  border-bottom: 1px solid var(--Neutral-200, #d8d9df);
  border-left: 1px solid var(--Neutral-200, #d8d9df);
  background: var(--common-white_states-main, #fff);
}

.p-p-payment-portal-card-info-container::placeholder,
.p-p-payment-portal-card-info-input-2::placeholder {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.p-p-payment-portal-amount-footer-main:hover {
  border-radius: 4px;
  border: 1.5px solid var(--Success-500, #5d9e44);
  background: var(--common-white_states-main, #fff);
}

.paymment-popup-content-button {
  display: flex;
  padding: 12px 132px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: #1077d1;
  cursor: pointer;
  user-select: none;
}

.payment-popup-content-button-text {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  text-align: center;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.paymment-processing-popup-content {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-top: 280px;
}

.paymment-processing-popup-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.paymment-processing-popup-content-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.paymment-processing-popup-content-header-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1.5rem */
}

.paymment-processing-popup-content-footer-text {
  color: var(--Neutral-900, #383842);
  text-align: center;

  /* Paragraph 01/P1 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
}

.paymment-processing-popup-content-footer-text-footer {
  color: var(--secondary-electric-violet-700, #4e2bd7);

  /* Paragraph 01/P1 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.gp-dot-slider-container {
  display: flex;
  width: fit-content;
  height: 6px;
  padding: 0px 1.5px 0px 0.5px;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  border-radius: var(--space-8px, 8px);
}

.gp-dot-slider {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-200, #d8d9df);
}

.gp-dot-slider-active {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-800, #3f25ae);
}

.l-search-result {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 54px;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 3;
  max-height: 350px;
}

.l-search-result-content-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  padding-top: 12px;
  border-top: none;
  border-bottom: 1px solid #eeeef0;
  width: 100%;
}

.l-search-result-content-container:hover {
  background: var(--Neutral-50, #f7f7f8);
}

.l-search-container {
  display: flex;
  gap: 6px;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
  background: transparent;
}

.l-search-result-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.l-search-result-first-section {
  display: flex;
  align-items: center;
  gap: 50px;
  flex: 1 0 0;
}

.l-search-result-first-text-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.l-search-result-second-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.l-search-result-second-text {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.l-search-result-first-text-title {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.l-search-result-first-text-description-container {
  display: flex;
  padding: 2px 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  background: var(--Neutral-50, #f7f7f8);
}

.l-search-result-first-text-description {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
}

.l-search-result-favorite-icon {
  cursor: pointer;
  user-select: none;
}

.whitelist-popup-checkboxes {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.whitelist-popup-checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.whitelist-popup-checkbox {
  width: 24px;
  height: 24px;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  border: 2px solid #603ff4;
  appearance: none;
}

.whitelist-popup-input-text {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.whitelist-popup-input-label {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.whitelist-popup-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
  align-self: stretch;
  margin-top: 490px;
}

.whitelist-popup-button {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-950, #0c0c0e);
  cursor: pointer;
  user-select: none;
}

.whitelist-popup-button-active {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  background: var(--primary-bitter-lemon-500, #cfe600);
  cursor: pointer;
  user-select: none;
}

.whitelist-popup-button-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.whitelist-popup-input {
  display: flex;
  padding: 13.5px 12px;
  align-items: center;
  gap: 177px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
  width: 100%;
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.whitelist-popup-input::placeholder {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.success-popup-content-container {
  display: flex;
  width: 28.4375%;
  padding: 117px 24px 194px 24px;
  flex-direction: column;
  align-items: center;
  background: var(--common-white_states-main, #fff);
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.12);
  height: 100%;
}

.success-popup-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.success-popup-content-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.success-popup-content-text-header {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;
  font-family: Manrope;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 2.1rem */
}

.success-popup-content-text-footer {
  width: 280px;
  color: var(--Neutral-600, #5b5d6e);
  text-align: center;

  /* Paragraph 01/P1 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
}

.search-filter-popup {
  display: flex;
  width: 28.4375%;
  height: 704px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  background: var(--common-white_states-main, #fff);
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.12);
  height: 100%;
}

.search-filter-popup-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  overflow-x: hidden;
  overflow-y: auto;
}

.search-filter-popup-header {
  display: flex;
  height: 36px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.search-filter-popup-header-main {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.search-filter-popup-header-label {
  color: var(--secondary-electric-violet-600, #603ff4);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  cursor: pointer;
  user-select: none;
}

.search-filter-popup-header-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.search-filter-popup-header-icon {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
}

.search-filter-popup-footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.search-filter-popup-footer-content {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.search-filter-popup-footer-search {
  display: flex;
  height: 48px;
  padding: 12px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--Neutral-50, #f7f7f8);
}

.search-filter-popup-footer-input {
  border-radius: 6px;
  background: var(--Neutral-50, #f7f7f8);
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}

.search-filter-popup-footer-input::placeholder {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.search-filter-popup-footer-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-right: 1px solid var(--Neutral-100, #eeeef0);
}

.search-filter-popup-footer-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.search-filter-popup-footer-type {
  border-top: none;
  border-bottom: 1px solid #f7f7f8;
  width: 100%;
  padding-bottom: 30px;
  padding-right: 30px;
}

.search-filter-popup-footer-type-text-active {
  color: var(--secondary-electric-violet-600, #603ff4);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  cursor: pointer;
  user-select: none;
}

.search-filter-popup-footer-type-text {
  color: var(--Neutral-500, #717286);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  cursor: pointer;
  user-select: none;
}

.search-filter-popup-footer-type-result-header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.search-filter-popup-footer-type-result-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.search-filter-popup-footer-type-result-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  overflow-x: hidden;
  overflow-y: auto;
}

.search-filter-popup-footer-type-result-main-text {
  color: var(--Text-Low-Emphasis, #646667);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  align-self: stretch;
}

.search-filter-popup-footer-type-result-main-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.search-filter-popup-footer-type-result-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
}

.search-filter-popup-footer-type-result-text-header {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.search-filter-popup-footer-type-result-text-footer {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.search-filter-popup-footer-type-result-checkbox {
  cursor: pointer;
  user-select: none;
}

.asset-info-popup-icons {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  text-align: right;
  flex: 1;

  justify-content: flex-end;
}

.asset-info-popup-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.asset-info-popup-header-main {
  display: flex;
  padding-bottom: 4px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.asset-info-popup-header-body {
  display: flex;
  padding: var(--space-0, 0px) 20px;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;
  border-bottom: 1px solid var(--Neutral-50, #f7f7f8);
}

.asset-info-popup-header-main-text {
  display: flex;
  align-items: center;
  gap: 2px;
}

.asset-info-popup-header-menu-text-active {
  color: var(--secondary-electric-violet-800, #3f25ae);
  text-align: center;

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  width: 159px;
}

.asset-info-popup-header-text-menu {
  color: var(--Neutral-500, #717286);
  text-align: center;

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  width: 158px;
}

.asset-info-popup-header-menu {
  display: flex;
  padding-top: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  cursor: pointer;
  user-select: none;
}

.asset-info-popup-header-menu-line {
  height: 3px;
  align-self: stretch;
  border-radius: 2px;
  background: var(--secondary-electric-violet-800, #3f25ae);
}

.asset-info-popup-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.asset-info-popup-first {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.asset-info-popup-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.asset-info-popup-first-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.asset-info-popup-first-footer {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
}

.asset-info-popup-first-header-title {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.asset-info-popup-first-header-price {
  display: flex;
  height: 41px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
}

.asset-info-popup-first-header-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.asset-info-popup-first-product-name {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.asset-info-popup-first-product-description {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.asset-info-popup-first-price-header {
  color: var(--Neutral-950, #0c0c0e);

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.asset-info-popup-first-price-footer {
  color: var(--Success-600, #41732f);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.asset-info-popup-first-footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--space-8px, 8px);
  width: 33.33%;
}

.asset-info-popup-first-footer-line {
  border-right: 1px solid #d8d9df;
  border-left: 1px solid #d8d9df;
  border-top: none;
  border-bottom: none;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-left: 8px;
}

.asset-info-popup-first-footer-content-header {
  color: var(--Neutral-700, #4a4a5a);
  text-align: center;

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
  width: 90px;
}

.asset-info-popup-first-footer-content-footer {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  width: 90px;
}

.asset-info-popup-first-header-icon {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 14px 8px 13.615px 8px;
  justify-content: center;
  align-items: center;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
  overflow: hidden;
}

.asset-info-popup-second-header-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.asset-info-popup-second-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  position: relative;
}

.asset-info-popup-second-body-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.asset-info-popup-second-body-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.asset-info-popup-second-body-header-text-container {
  display: flex;
  padding: 4px;
  align-items: center;
  border-radius: 32px;
  border: 1px solid var(--Neutral-200, #d8d9df);
}

.asset-info-popup-second-body-header-text-footer {
  color: var(--Neutral-800, #40414c);
  text-align: center;

  /* Paragraph 04/P2 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 0.875rem */
  padding-right: 20px;
}

.asset-info-popup-second-body-header-button-active {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--secondary-electric-violet-500, #6756fe);
  cursor: pointer;
  user-select: none;
}

.asset-info-popup-second-body-header-button-text-active {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  text-align: center;

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  width: 60px;
}

.asset-info-popup-second-body-header-button {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
}

.asset-info-popup-second-body-header-button-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  width: 60px;
}

.asset-info-popup-graph-number-icon {
  width: 100%;
  height: 80%;
}

.asset-info-popup-graph-price-icon {
  z-index: 3;
  position: absolute;
  top: 40px;
  width: 90%;
  height: 80%;
}

.asset-info-popup-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.asset-info-popup-button-sell {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  background: var(--Error-700, #bc1919);
  cursor: pointer;
  user-select: none;
}

.asset-info-popup-button-buy {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  background: var(--Success-600, #41732f);
  cursor: pointer;
  user-select: none;
}

.asset-info-popup-button-text {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  text-align: center;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.asset-info-popup-second-body-description-container {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Accent-50, #fffbdd);
  overflow: hidden;
}

.asset-info-popup-second-body-description-text {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  flex: 1 0 0;
  overflow: hidden;
  max-height: 105px;
}

.asset-info-popup-second-body-description-text-full {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  flex: 1 0 0;
  overflow: hidden;
  height: fit-content;
}

.asset-info-popup-second-body-description-text-header {
  color: var(--Neutral-900, #383842);

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
}

.asset-info-popup-second-body-description-text-link {
  color: var(--secondary-electric-violet-700, #4e2bd7);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.asset-info-popup-second-body-description-link {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  flex: 1 0 0;
  cursor: pointer;
  user-select: none;
}

.asset-info-popup-trading-first-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.asset-info-popup-trading-first-footer {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  background: var(--Accent-100, #fff9c1);
  position: relative;
}

.asset-info-popup-trading-first-header-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.asset-info-popup-trading-first-header-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.asset-info-popup-trading-first-header-text-footer {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.asset-info-popup-trading-input {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-200, #d8d9df);
  background: var(--Neutral-50, #f7f7f8);
  color: var(--Neutral-950, #0c0c0e);

  /* Heading/H4 */
  font-family: Manrope;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.5rem */
}

.asset-info-popup-trading-input::placeholder {
  color: var(--Neutral-300, #b6b7c3);

  /* Heading/H4 */
  font-family: Manrope;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.5rem */
}

.asset-info-popup-trading-input-group {
  display: flex;
  align-items: flex-start;
  gap: 34px;
  align-self: stretch;
}

.asset-info-popup-trading-price-input-group {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.asset-info-popup-trading-categories {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Neutral-50, #f7f7f8);
}

.asset-info-popup-trading-price-toggle {
  display: flex;
  padding: var(--space-8px, 8px) 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Success-50, #f1f8ed);
  cursor: pointer;
  user-select: none;
  min-width: max-content;
}

.asset-info-popup-trading-price-input {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 0;
  border-radius: 4px;
  background: var(--Neutral-50, #f7f7f8);
  max-width: 85px;
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;
  /* Paragraph 01/P1 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
}

.asset-info-popup-trading-price-input:disabled {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 01/P1 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
}

.asset-info-popup-trading-price-input::placeholder {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 01/P1 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
}

.asset-info-popup-trading-category-active {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  background: var(--secondary-electric-violet-500, #6756fe);
  cursor: pointer;
  user-select: none;
  max-width: 50%;
}

.asset-info-popup-trading-category {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  max-width: 50%;
}

.asset-info-popup-trading-category-text-active {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  text-align: center;

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  width: 38px;
}

.asset-info-popup-trading-category-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  width: 38px;
}

.asset-info-popup-trading-first-footer-text {
  color: var(--Accent-800, #89490a);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.asset-info-popup-trading-first-footer-body {
  display: flex;
  align-items: center;
  gap: 4px;
}

.asset-info-popup-trading-info-icon {
  cursor: pointer;
  user-select: none;
}

.asset-info-popup-second-trading-header-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.asset-info-popup-second-trading-body {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--common-white_states-main, #fff);
}

.asset-info-popup-second-trading-body-header {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.asset-info-popup-second-trading-body-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.asset-info-popup-second-trading-body-line {
  width: 100%;
  height: 1px;
  background: #eeeef0;
}

.asset-info-popup-second-trading-body-icon-first {
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: var(--secondary-electric-violet-50, #ecefff);
}

.asset-info-popup-second-trading-body-icon-second {
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
}

.asset-info-popup-second-trading-body-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  flex: 1 0 0;
}

.add-funds-content-footer-balance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
}

.add-funds-content-footer-balance-first {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.add-funds-content-footer-balance-second {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
}

.add-funds-popup-a-f-button-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  min-width: max-content;
}

.slippage-hover-content {
  display: none;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--common-white_states-main, #fff);
  position: absolute;
  z-index: 3;
}

.asset-info-popup-trading-slippage-icon {
  cursor: pointer;
  user-select: none;
}

.asset-info-popup-trading-slippage-icon:hover + .slippage-hover-content {
  display: block;
}

.slippage-hover-text {
  color: var(--Neutral-900, #383842);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.asset-info-popup-p-and-l {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.asset-info-popup-p-and-l {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.asset-info-popup-p-and-l-content {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.asset-info-popup-p-and-l-content-section {
  display: flex;
  padding: var(--space-8px, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  border-radius: 6px;
  background: var(--Neutral-50, #f7f7f8);
}

.asset-info-popup-p-and-l-content-title {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  width: max-content;
}

.asset-info-popup-p-and-l-content-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.asset-info-popup-p-and-l-content-text-gain {
  color: var(--Success-700, #38602b);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.asset-info-popup-p-and-l-content-text-loss {
  color: var(--Error-700, #bc1919);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.asset-info-popup-p-and-l-content-text-container {
  display: flex;
  align-items: center;
}

.asset-info-popup-trading-container {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
}

.asset-info-popup-collateral-main-second-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.asset-info-popup-collateral-main-second-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.asset-info-popup-collateral-main-second {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-200, #d8d9df);
  cursor: pointer;
}

.asset-info-popup-collateral-main-second-footer {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
}

.asset-info-popup-collateral-main-title {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.asset-info-popup-collateral-main-first-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 6px;
}

.asset-info-popup-collateral-main-first-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.asset-info-popup-collateral-main-first-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.asset-info-popup-collateral-main-first {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.asset-info-popup-collateral-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.asset-info-popup-collateral-body {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--common-white_states-main, #fff);
}

.asset-info-popup-collateral-header-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.asset-info-popup-collateral-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.asset-info-popup-collateral-header-text-title {
  align-self: stretch;
  color: var(--Neutral-950, #0c0c0e);
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 1.4rem */
}

.asset-info-popup-collateral-button {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--primary-bitter-lemon-500, #cfe600);
  cursor: pointer;
  user-select: none;
}

.asset-info-popup-collateral-button-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.asset-info-popup-second-trading-body-fotter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.asset-info-popup-second-trading-body-fotter-first {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.asset-info-popup-second-trading-body-fotter-second {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;
}

.asset-info-popup-second-trading-body-fotter-second-text {
  color: var(--secondary-electric-violet-700, #4e2bd7);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  text-decoration-line: underline;
}

.asset-info-popup-second-trading-body-fotter-first-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.asset-info-popup-second-trading-body-fotter-first-title-text {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.asset-info-popup-second-trading-body-fotter-first-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.asset-info-popup-second-trading-body-fotter-first-button {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  background: var(--secondary-electric-violet-900, #362689);
  cursor: pointer;
  user-select: none;
}

.asset-info-popup-second-trading-body-fotter-first-button-text {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.loans-popup-page {
  display: inline-flex;
  height: 632px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);
  margin-bottom: 69px;
}

.loans-popup-page-icon {
  display: flex;
  align-items: center;
  gap: 38px;
}

.borrow-popup-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  overflow-y: auto;
}

.borrow-popup-content-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.borrow-popup-content-header-first {
  display: flex;
  height: 36px;
  align-items: center;
  gap: 156px;
  align-self: stretch;
}

.borrow-popup-content-header-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.2rem */
}

.borrow-popup-content-header-second {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
}

.borrow-popup-content-slider-active {
  height: 4px;
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-600, #603ff4);
}

.borrow-popup-content-slider {
  height: 4px;
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  background: #eeeef0;
}

.borrow-popup-content-body {
  display: flex;
  /* height: 484px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.borrow-popup-content-body-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.borrow-popup-content-body-main-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.borrow-popup-content-body-main-first-header-text {
  color: #000;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.borrow-popup-content-body-main-first-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.borrow-popup-content-body-main-first-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);
}

.borrow-popup-content-body-main-first-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.borrow-popup-content-body-main-first-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.borrow-popup-content-body-main-first-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.borrow-popup-content-body-main-first-footer {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  overflow-x: auto;
  overflow-y: hidden;
}

.borrow-popup-content-body-main-first-footer-content {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--secondary-electric-violet-700, #4e2bd7);
}

.borrow-popup-content-body-main-first-footer-text {
  color: var(--secondary-electric-violet-700, #4e2bd7);
  text-align: center;

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
  width: 40px;
}

.borrow-popup-content-body-main-second {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
}

.borrow-popup-content-body-main-second-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-content-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-content-header-slider {
  width: 100%;
  height: 5px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #eeeef0;
  position: relative;
}

.borrow-popup-content-body-main-second-content-header-slider-line {
  width: 89px;
  height: 5px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--secondary-electric-violet-600, #603ff4);
}

.borrow-popup-close-icon {
  position: absolute;
  right: 20px;
}

.borrow-popup-content-body-main-second-content-header-slider-eclipse {
  position: absolute;
  top: -5px;
}

.borrow-popup-content-body-main-second-content-footer {
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 47px;
  position: relative;
  width: 100%;
}

.borrow-popup-content-body-main-second-content-footer-text {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  cursor: pointer;
  user-select: none;
  width: 20%;
}

.borrow-popup-content-body-main-third-content {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.borrow-popup-content-body-main-third-content-main {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-50, #ecefff);
}

.borrow-popup-content-body-main-third-content-main-header {
  color: var(--Neutral-800, #40414c);
  text-align: center;

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.borrow-popup-content-body-main-third-content-main-footer {
  color: var(--secondary-electric-violet-900, #362689);
  text-align: center;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.borrow-popup-content-body-main-last-content {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  background: var(--Accent-100, #fff9c1);
}

.borrow-popup-content-body-main-last-content-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.borrow-popup-content-body-main-last-content-text {
  color: var(--Accent-800, #89490a);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.borrow-popup-content-body-footer {
  display: flex;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  margin-bottom: 10px;
}

.borrow-popup-content-body-footer-container {
  display: flex;
  padding: 2px var(--space-0, 0px);
  align-items: flex-start;
  gap: 10px;
}

.borrow-popup-content-body-footer-text {
  flex: 1 0 0;
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  margin-bottom: 25px;
}

.checkbox-icon {
  cursor: pointer;
}

.borrow-warning-popup {
  display: flex;
  width: 364px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 45px;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.12);
}

.borrow-warning-popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.borrow-warning-popup-header-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  align-self: stretch;
}

.borrow-warning-popup-footer-text {
  color: var(--Neutral-600, #5b5d6e);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  width: 306px;
}

.borrow-popup-content-body-main-second-custom {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
}

.borrow-popup-content-body-main-second-custom-content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
  width: 100%;
  cursor: pointer;
}

.borrow-popup-content-body-main-second-custom-content-active {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-50, #ecefff);
  cursor: pointer;
}

.borrow-popup-content-body-main-second-custom-content-header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-content-header-first {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.borrow-popup-content-body-main-second-custom-content-header-first-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
}

.borrow-popup-content-body-main-second-custom-content-header-title {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.borrow-popup-content-body-main-second-custom-content-header-label {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-content-header-title-label {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.borrow-popup-content-body-main-second-custom-content-header-second-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1 0 0;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-content-header-second-title {
  color: var(--Neutral-950, #0c0c0e);
  text-align: right;

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
}

.borrow-popup-content-body-main-second-custom-content-header-second-label {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.borrow-popup-content-body-main-second-custom-content-footer {
  display: flex;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background: var(--common-white_states-main, #fff);
}

.borrow-popup-content-body-main-second-custom-content-footer-first {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  width: 80px;
}

.borrow-popup-content-body-main-second-custom-content-footer-first-text-label {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.borrow-popup-content-body-main-second-custom-content-footer-last {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.borrow-popup-content-body-main-second-custom-content-footer-last-text {
  color: var(--Neutral-400, #8f91a1);
  text-align: right;

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  width: 120px;
}

.borrow-popup-content-body-main-second-custom-content-footer-last-text-label {
  color: var(--Success-600, #41732f);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.borrow-popup-content-body-main-second-custom-content-footer-last-text-label-loss {
  color: var(--Error-700, #bc1919);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.borrow-popup-back-icon {
  cursor: pointer;
}

.borrow-popup-content-body-main-second-custom-third {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-content-frameless {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.borrow-popup-content-body-main-second-custom-third-main {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
}

.borrow-popup-content-body-main-second-custom-third-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-third-header-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-third-header-first-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
}

.borrow-popup-content-body-main-second-custom-third-header-first-header {
  display: flex;
  padding: var(--space-8px, 8px) 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-third-header-second {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.borrow-popup-content-body-main-second-custom-third-header-second-body {
  display: flex;
  width: 100%;
  padding: 10px var(--space-0, 0px);
  justify-content: space-between;
  align-items: center;
}

.borrow-popup-content-body-main-second-custom-third-footer {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  background: #ecefff;
}

.borrow-popup-content-body-main-second-custom-third-footer-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.borrow-popup-content-body-main-second-custom-third-footer-text {
  color: var(--secondary-electric-violet-900, #362689);
  text-align: center;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.borrow-popup-content-body-main-second-custom-third-header-first-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
}

.borrow-popup-content-body-main-second-custom-third-header-second-body-text {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.borrow-popup-content-body-main-second-custom-last-main {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-last-main-content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
}

.borrow-popup-content-body-main-second-custom-last-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.borrow-popup-content-body-main-second-custom-last-main-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.borrow-confirmation-footer-text {
  flex: 1 0 0;
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.borrow-confirmation-footer-text-label {
  color: var(--secondary-electric-violet-700, #4e2bd7);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
}

.borrow-confirmation-popup-header-text {
  color: var(--Neutral-600, #5b5d6e);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-last {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
}

.borrow-popup-content-body-main-second-custom-last-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-last-header {
  display: flex;
  height: 64px;
  padding: 12px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
}

.borrow-popup-content-body-main-second-custom-last-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.borrow-popup-content-body-main-second-custom-last-label-text {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.borrow-popup-content-body-main-second-custom-last-header-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: right;

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.borrow-popup-content-body-main-second-custom-last-main {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-last-main-content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-last-main-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.borrow-popup-content-body-main-second-custom-last-footer {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
}

.borrow-popup-content-body-main-second-custom-last-footer-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-last-footer-first {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.borrow-popup-content-body-main-second-custom-last-footer-first-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
}

.borrow-popup-content-body-main-second-custom-last-footer-first-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.borrow-popup-content-body-main-second-custom-last-footer-second {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1 0 0;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-last-footer-container {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.borrow-popup-content-body-main-second-custom-last-footer-content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
}

.borrow-popup-content-body-main-second-custom-last-footer-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}

.offer-security-popup-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  overflow-x: hidden;
  overflow-y: auto;
}

.offer-security-popup-body-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.offer-security-popup-body-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.offer-security-popup-body-first-container {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1.5px solid var(--Neutral-100, #eeeef0);
  background: var(--common-white_states-main, #fff);
}

.offer-security-popup-body-first-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.offer-security-popup-body-first-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.offer-security-popup-body-first-content-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.offer-security-popup-body-first-content-text-container-option {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  justify-content: center;
}

.offer-security-popup-body-first-content-text-container-option:hover {
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-50, #ecefff);
}
.offer-security-popup-body-first-content-text {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.offer-security-popup-body-first-content-footer {
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
}

.offer-security-popup-body-first-content-footer-icon {
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Neutral-200, #d8d9df);
  overflow: hidden;
}

.offer-security-popup-body-first-content-footer-text-container {
  display: flex;
  align-items: center;
  gap: 2px;
}

.offer-security-popup-body-first-content-footer-text {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.offer-security-popup-body-first-content-footer-mult {
  display: flex;
  height: 47px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-200, #d8d9df);
  position: relative;
}

.offer-security-popup-body-first-content-footer-text-container-wrap {
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  overflow: hidden;
}

.offer-security-popup-body-first-content-footer-text-outer {
  display: flex;
  padding: 4px 4px 4px 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--secondary-electric-violet-50, #ecefff);
  min-width: fit-content;
}

.offer-security-popup-body-first-content-footer-text-outer-more {
  display: flex;
  padding: 4px 4px 4px 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--secondary-electric-violet-50, #ecefff);
  min-width: fit-content;
}

.offer-security-popup-body-first-content-footer-text-mult {
  color: var(--secondary-electric-violet-900, #362689);
  width: fit-content;
  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  word-wrap: break-word;
}

.offer-security-popup-body-second-header {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.offer-security-popup-body-second-header-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.offer-security-popup-body-second-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  cursor: pointer;
  user-select: none;
  position: relative;
}

.offer-security-popup-body-second-body-container {
  display: flex;
  padding: 14px 24px;
  flex-direction: column;
  align-items: center;
  gap: var(--space-8px, 8px);
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px dashed var(--Neutral-200, #d8d9df);
  background: var(--common-white_states-main, #fff);
  width: 100%;
}

.offer-security-popup-body-second-body-container-drop {
  display: flex;
  padding: 14px 24px;
  flex-direction: column;
  align-items: center;
  gap: var(--space-8px, 8px);
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px dashed var(--secondary-electric-violet-800, #3f25ae);
  background: var(--secondary-electric-violet-50, #ecefff);
  width: 100%;
}

.offer-security-popup-body-second-body-container:hover {
  border-radius: var(--space-8px, 8px);
  border: 1px dashed var(--secondary-electric-violet-800, #3f25ae);
  background: var(--secondary-electric-violet-50, #ecefff);
}

.offer-security-popup-body-second-body-icon {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 6.545px;
  justify-content: center;
  align-items: center;
  gap: 7.273px;
  border-radius: 36.364px;
  background: var(--secondary-electric-violet-50, #ecefff);
}

.offer-security-popup-body-second-body-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.offer-security-popup-body-second-body-first-text {
  color: var(--secondary-electric-violet-800, #3f25ae);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.offer-security-popup-body-second-body-second-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Hind;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.offer-security-popup-body-second-input {
  cursor: pointer;
  display: none;
}

.offer-security-info-icon {
  cursor: pointer;
}

.offer-security-popup-info-content {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--common-white_states-main, #fff);
  position: absolute;
  top: 17px;
  left: -8px;
  width: max-content;
  border-radius: 2px;
  z-index: 3;

  /* Shadow Nav 1 */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
}

.offer-security-popup-info-content-text {
  color: var(--Neutral-900, #383842);
  width: auto;
  max-width: 300px;
  height: fit-content;
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.offer-security-popup-body-second-header-icon {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
  position: relative;
}

.offer-security-popup-body-second-body-file-container {
  display: flex;
  padding: var(--space-8px, 8px) 12px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--common-white_states-main, #fff);
}

.offer-security-popup-body-second-body-file-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
}

.offer-security-popup-body-second-body-file-header {
  display: flex;
  width: 100%;
  height: 36px;
  align-items: flex-start;
  gap: 4px;
}

.offer-security-popup-body-second-body-file-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.offer-security-popup-body-second-body-file-text {
  color: #989692;
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.25rem; /* 200% */
}

.offer-security-popup-body-second-body-file-footer {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.offer-security-popup-body-second-body-file-footer-container {
  height: 6px;
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
}

.offer-security-popup-body-second-body-file-line-outer {
  width: 100%;
  height: 6px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Neutral-100, #eeeef0);
  position: relative;
}

.offer-security-popup-body-second-body-file-line-inner {
  width: 105.165px;
  height: 6px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--primary-bitter-lemon-600, #9eb500);
  position: absolute;
  top: 0;
}

.offer-security-popup-body-second-body-file-footer-text {
  color: var(--primary-bitter-lemon-700, #778902);
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 200% */
}

.offer-security-popup-body-second-body-file-title {
  color: #353535;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 166.667% */
}

.corporate-action-popup-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}

.corporate-action-popup-main-container {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--common-white_states-main, #fff);
}

.corporate-action-popup-main-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.corporate-action-popup-main-header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.corporate-action-popup-main-body {
  display: flex;
  padding: var(--space-8px, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--Neutral-50, #f7f7f8);
}

.corporate-action-popup-main-body-title {
  color: var(--Neutral-900, #383842);

  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
}

.corporate-action-popup-main-body-label {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  align-self: stretch;
}

.corporate-action-popup-main-footer {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-200, #d8d9df);
  cursor: pointer;
}

.corporate-action-popup-main-footer-body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.corporate-action-popup-main-footer-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  width: 202px;
}

.profile-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.profile-popup-content-first {
  display: flex;
  padding: var(--space-8px, 8px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);

  /* Shadow Nav 1 */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.profile-popup-content-first-container {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.profile-popup-content-first-icon {
  display: flex;
  width: 54px;
  height: 54px;
  justify-content: center;
  align-items: center;
  border-radius: var(--space-8px, 8px);
  overflow: hidden;
}

.profile-popup-content-first-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
}

.profile-popup-content-first-text-title {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.profile-popup-content-first-text-label {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.profile-popup-content-second {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);

  /* Shadow Nav 1 */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
}

.profile-popup-content-second-text {
  color: var(--Neutral-900, #383842);
  text-align: center;

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
}

.profile-popup-content-second-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.profile-popup-content-second-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  border-radius: 24px;
  cursor: pointer;
}

.profile-popup-content-second-content-icon {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: var(--space-8px, 8px);
  border-radius: 4px;
  background: var(--secondary-electric-violet-50, #ecefff);
}

.profile-popup-content-second-content-text {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 150% */
  text-transform: capitalize;
  width: fit-content;
  max-width: 60px;
  height: 36px;
}

.profile-popup-content-third {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);

  /* Shadow Nav 1 */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
}

.profile-popup-content-third-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.profile-popup-content-third-content {
  display: flex;
  padding: 4px var(--space-0, 0px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  cursor: pointer;
  /* box-shadow: 2px 2px 12px 0px rgba(122, 122, 122, 0.12); */
}

.profile-popup-content-third-content-text-container {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.profile-popup-content-third-content-text {
  color: var(--Neutral-900, #383842);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-popup-content-third-content-line {
  width: 100%;
  height: 1px;
  background: #eeeef0;
}

.profile-popup-show-details-header {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.profile-popup-show-details-header-icon {
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  border-radius: var(--space-8px, 8px);
  position: relative;
  overflow: hidden;
}

.profile-popup-show-details-header-icon-container {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  right: -5.5px;
  bottom: -5px;
  border-radius: 4px;
  background: var(--common-white_states-main, #fff);

  /* Shadow Nav 1 */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
}

.profile-popup-show-details-header-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.profile-popup-show-details-header-title {
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.profile-popup-show-details-header-label-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.profile-popup-show-details-header-label {
  color: var(--Neutral-500, #717286);
  text-align: center;

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.profile-popup-show-details-body {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--common-white_states-main, #fff);
}

.profile-popup-show-details-body-title {
  color: var(--Neutral-900, #383842);
  text-align: center;

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
}

.profile-popup-show-details-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.profile-popup-show-details-body-content-first {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.profile-popup-show-details-body-content-first-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.profile-popup-show-details-body-content-first-title {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.profile-popup-show-details-body-content-first-label {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.profile-popup-show-details-body-content-line {
  background: #eeeef0;
  width: 100%;
  height: 1px;
}

.profile-popup-show-details-body-content-second {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.profile-popup-clicakable-icon {
  cursor: pointer;
}

.profile-pin-popup-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.profile-pin-popup-header-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1rem */
  align-self: stretch;
}

.profile-pin-popup-header-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.profile-pin-popup-header-body-text {
  color: var(--Neutral-600, #5b5d6e);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  align-self: stretch;
}

.profile-pin-popup-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.profile-pin-popup-body-text {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-pin-popup-input-group {
  display: flex;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.profile-pin-popup-input {
  display: flex;
  padding: 20px 12px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
  color: var(--Neutral-950, #0c0c0e);
  text-align: center;

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.profile-pin-popup-input-label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.profile-pin-popup-input-label-text {
  flex: 1 0 0;
  color: var(--Error-600, #e23636);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.profile-pin-popup-input-icon {
  height: 24px;
  width: 24px;
}

.profile-edit-contact-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.profile-edit-contact-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.profile-edit-contact-text {
  color: #000;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  align-self: stretch;
}

.profile-edit-contact-input {
  display: flex;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.profile-edit-contact-first-section {
  display: flex;
  width: 109px;
  padding: 12px;
  align-items: center;
  gap: 6px;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
}

.profile-edit-contact-second-section {
  display: flex;
  height: 48px;
  padding: 12px;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-edit-contact-second-section::placeholder {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-edit-contact-selection-icon {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  overflow: hidden;
}

.profile-edit-contact-selection-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: right;

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  width: 33px;
  flex-shrink: 0;
}

.profile-edit-contact-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.profile-edit-contact-footer-first-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.profile-edit-contact-otp-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.profile-edit-contact-otp-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.profile-edit-contact-otp-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.profile-edit-contact-otp-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1rem */
  align-self: stretch;
}

.profile-edit-contact-otp-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.profile-edit-contact-otp-header-text {
  align-self: stretch;
  color: var(--Neutral-600, #5b5d6e);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-edit-contact-otp-header-text-label {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.profile-edit-contact-otp-header-body {
  display: flex;
  align-items: center;
  gap: 4px;
}

.profile-edit-contact-otp-header-body-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Sub Heading/SH1 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 1rem */
}

.profile-edit-contact-otp-second-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.profile-edit-contact-otp-second-body-header {
  display: flex;
  width: 40px;
  height: 17px;
  padding-right: 9px;
  align-items: center;
}

.profile-edit-contact-otp-second-body-header-text {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.profile-edit-contact-otp-second-body-header-text-label {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.profile-edit-contact-otp-second-body-header-label {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.profile-edit-contact-otp-second-body-header-text-red {
  color: var(--Error-600, #e23636);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.profile-edit-contact-otp-second-body-header-text-label-red {
  color: var(--Error-600, #e23636);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.profile-edit-contact-otp-second-body-header-label-active {
  color: var(--secondary-electric-violet-600, #603ff4);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  cursor: pointer;
  user-select: none;
}

.profile-edit-contact-input-error {
  display: flex;
  align-items: center;
  gap: 4px;
}

.input-error-text {
  color: var(--Error-600, #e23636);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.profile-edit-contact-input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
}

.profile-edit-contact-otp-second-body-header-content-text {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.profile-edit-contact-otp-second-body-header-content-text-red {
  color: var(--Error-600, #e23636);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.profile-edit-country-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.profile-edit-country-header-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 1rem */
  align-self: stretch;
}

.profile-edit-country-header-body {
  display: flex;
  height: 48px;
  padding: 12px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Neutral-100, #eeeef0);
}

.profile-edit-country-header-body-text-container {
  display: flex;
  align-items: center;
  gap: 2px;
}

.profile-edit-country-header-body-text {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.profile-edit-country-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.profile-edit-country-body-first {
  display: flex;
  align-items: center;
  gap: 21px;
  align-self: stretch;
}

.profile-edit-country-body-line {
  height: 1px;
  flex: 1 0 0;
  stroke-width: 1px;
  background-color: var(--Neutral-100, #eeeef0);
}

.profile-edit-country-body-text {
  color: var(--Neutral-500, #717286);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-edit-country-body-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.profile-edit-country-body-input-container {
  display: flex;
  height: 48px;
  padding: 12px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Neutral-200, #d8d9df);
  background: var(--Neutral-50, #f7f7f8);
}

.profile-edit-country-body-input {
  display: flex;
  align-items: center;
  gap: 2px;
  background: transparent;
  flex: 1;
}

.profile-edit-country-body-input::placeholder {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-edit-country-body-content-group {
  display: flex;
  padding: var(--space-8px, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-50, #f7f7f8);
  background: var(--common-white_states-main, #fff);
  /* height: 288px; */
  overflow-x: hidden;
  overflow-y: auto;
}

.profile-edit-country-body-content {
  display: flex;
  padding: var(--space-0, 0px) var(--space-8px, 8px);
  align-items: center;
  gap: 19px;
  align-self: stretch;
  padding: var(--space-8px, 8px);
  cursor: pointer;
  border-radius: 6px;
  background: transparent;
}

.profile-edit-country-body-content-active {
  display: flex;
  padding: var(--space-0, 0px) var(--space-8px, 8px);
  align-items: center;
  gap: 19px;
  align-self: stretch;
  padding: var(--space-8px, 8px);
  cursor: pointer;
  border-radius: 6px;
  background: var(--Success-50, #f1f8ed);
}

.profile-edit-country-body-content-icon {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: var(--common-white_states-main, #fff);
  box-shadow: 0px 1px 4px 0px #eee;
  overflow: hidden;
}

.profile-edit-country-body-content-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-popup-icon {
  cursor: pointer;
}

.profile-popup-content-settings-text {
  color: var(--Neutral-900, #383842);
  text-align: center;

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
}

.profile-popup-content-settings-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.profile-popup-content-settings-first {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  /* border-radius: var(--space-8px, 8px);
box-shadow: 2px 2px 12px 0px rgba(122, 122, 122, 0.12); */
}

.profile-popup-content-settings-first-text-container {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.profile-popup-content-settings-first-text {
  color: var(--Neutral-900, #383842);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-popup-content-settings-line {
  width: 100%;
  height: 1px;
  background: #eeeef0;
}

.profile-popup-content-settings-second {
  display: flex;
  padding: 4px var(--space-0, 0px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  /* border-radius: var(--space-8px, 8px);
box-shadow: 2px 2px 12px 0px rgba(122, 122, 122, 0.12); */
  cursor: pointer;
  user-select: none;
}

.profile-popup-content-settings-first-text-red {
  color: var(--Error-700, #bc1919);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-co-signer-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  overflow-x: hidden;
  overflow-y: auto;
}

.profile-co-signer-body-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.profile-co-signer-body-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.profile-co-signer-body-header-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.profile-co-signer-body-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.profile-co-signer-body-header-text {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 1rem */
  align-self: stretch;
}

.profile-co-signer-body-header-main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.profile-co-signer-body-header-main-content-title {
  color: var(--Neutral-600, #5b5d6e);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  align-self: stretch;
}

.profile-co-signer-body-header-main-content-container {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Accent-100, #fff9c1);
}

.profile-co-signer-body-header-main-content-text {
  color: var(--Accent-800, #89490a);

  /* Caption/C1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.profile-co-signer-body-header-main-content-label {
  color: var(--Accent-800, #89490a);

  /* Caption/C2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
}

.profile-co-signer-body-footer-first {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.profile-co-signer-body-footer-first-text {
  color: var(--Neutral-950, #0c0c0e);
  text-align: right;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.profile-co-signer-body-footer-first-label {
  color: var(--Neutral-950, #0c0c0e);
  text-align: right;

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.profile-co-signer-body-footer-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.profile-co-signer-body-footer-second-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
}

.profile-co-signer-body-footer-second-main {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 14px;
}

.profile-co-signer-body-footer-second-content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--Neutral-50, #f7f7f8);
  position: relative;
  height: max-content;
}

.profile-co-signer-body-footer-second-content-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.profile-co-signer-body-footer-second-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
}

.profile-co-signer-body-footer-second-content-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.profile-co-signer-body-footer-second-content-first-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.profile-co-signer-body-footer-second-content-first-label {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.profile-co-signer-body-footer-second-content-second {
  display: flex;
  padding: 4px 6px;
  align-items: center;
  gap: 2px;
  border-radius: 6px;
  background: var(--Success-200, #c3e2b4);
}

.profile-co-signer-body-footer-second-content-second-text {
  color: var(--Success-800, #2f4e26);
  text-align: right;

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.profile-co-signer-body-footer-second-content-icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.profile-co-signer-body-footer-second-content-header {
  display: flex;
  padding: 4px 6px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  left: 12px;
  top: -14px;
  border-radius: 6px;
  background: var(--secondary-electric-violet-100, #dde1ff);
}

.profile-co-signer-body-footer-second-content-header-text {
  color: var(--secondary-electric-violet-900, #362689);
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 0.875rem */
}

.profile-co-signer-body-footer-second-footer {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;
}

.profile-co-signer-body-footer-second-footer-text {
  color: var(--secondary-electric-violet-700, #4e2bd7);
  text-align: right;

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.profile-edit-co-signer-body {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-200, #d8d9df);
  background: var(--common-white_states-main, #fff);
}

.profile-edit-co-signer-body-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.profile-edit-co-signer-body-header-first {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.profile-edit-co-signer-body-header-first-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.profile-edit-co-signer-body-header-second {
  display: flex;
  padding: 4px 6px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  background: var(--secondary-electric-violet-100, #dde1ff);
}

.profile-edit-co-signer-input {
  display: flex;
  padding: 13.5px 12px;
  align-items: center;
  gap: 177px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-edit-co-signer-input::placeholder {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-edit-co-signer-input-label {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.profile-edit-co-signer-input-label-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.profile-edit-co-signer-input-label-text {
  color: var(--Neutral-900, #383842);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.profile-edit-co-signer-buttons {
  display: flex;
  height: 48px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.profile-edit-co-signer-button {
  display: flex;
  padding: 7.5px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--secondary-electric-violet-600, #603ff4);
}

.profile-edit-co-signer-button-text {
  color: var(--secondary-electric-violet-600, #603ff4);
  text-align: right;

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.profile-edit-co-signer-button-active {
  display: flex;
  padding: 6px var(--space-8px, 8px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--secondary-electric-violet-600, #603ff4);
  background: var(--secondary-electric-violet-600, #603ff4);
}

.profile-edit-co-signer-button-text-active {
  color: var(--secondary-electric-violet-50, #ecefff);
  text-align: right;

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.profile-popup-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  width: 100%;
}

.profile-popup-card-footer-body-line {
  background: #f7f7f8;
  width: 100%;
  height: 1px;
}

.profile-popup-card-header {
  height: 202px;
  align-self: stretch;
}

.profile-popup-card-header-first {
  width: 100%;
  height: 184px;
  flex-shrink: 0;
  border-radius: var(--space-8px, 8px) var(--space-8px, 8px) 0px 0px;
  background: linear-gradient(180deg, #21174f 0%, #3c2d7d 100%);
  position: relative;
}

.profile-popup-card-header-first-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;

  /* padding-bottom: 60px; */
}

.profile-popup-card-header-first-header {
  display: flex;
  width: 100%;
  height: 62px;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

.profile-popup-card-header-first-header-text {
  color: var(--Neutral-300, #b6b7c3);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  letter-spacing: 0.18rem;
}

.profile-popup-card-header-first-header-label {
  display: flex;
  padding: var(--space-0, 0px) 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.profile-popup-card-header-first-header-label-text {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: 0.13125rem;
  width: 40px;
}

.profile-popup-card-header-first-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

.profile-popup-card-header-first-footer-first {
  display: flex;
  align-items: center;
  gap: 12px;
}

.profile-popup-card-header-first-footer-first-body {
  display: flex;
  align-items: center;
  gap: 6px;
}

.profile-popup-card-header-first-footer-first-main {
  display: flex;
  align-items: center;
  gap: 6px;
}

.profile-popup-card-header-first-footer-first-icon {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 4.167px 1.99px 4.167px 1.989px;
  justify-content: center;
  align-items: center;
}

.profile-popup-card-header-first-footer-first-body-text {
  color: var(--Neutral-300, #b6b7c3);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.profile-popup-card-header-first-footer-first-body-label {
  color: var(--Neutral-White-100, var(--common-white_states-main, #fff));

  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
}

.profile-popup-card-header-second {
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: var(--space-8px, 8px);
  background: var(--common-white_states-main, #fff);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
}

.profile-popup-card-header-second-container {
  display: flex;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background: var(--secondary-electric-violet-50, #ecefff);
}

.profile-popup-card-header-second-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.profile-popup-card-header-second-label {
  display: flex;
  align-items: center;
  gap: 6px;
}

.profile-popup-card-header-second-label-title {
  color: var(--secondary-electric-violet-900, #362689);

  /* Paragraph 01/P2 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
}

.profile-popup-card-header-second-label-text {
  color: #2a2689;
  text-align: right;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
}

.profile-popup-card-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.profile-popup-card-footer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.profile-popup-card-footer-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Sub Heading/SH2 */
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 1rem */
}

.profile-popup-card-footer-body {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
}

.profile-popup-card-footer-body-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  padding-bottom: 15px;
}

.profile-popup-card-footer-body-text {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

.borrow-popup-repay-footer {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.borrow-popup-repay-footer-content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-50, #ecefff);
}

.borrow-popup-repay-footer-content-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}

.borrow-popup-repay-footer-content-header-text {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  align-self: stretch;
}

.borrow-popup-repay-footer-content-text {
  color: var(--secondary-electric-violet-800, #3f25ae);
  text-align: right;

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.borrow-popup-content-body-main-label {
  display: flex;
  padding: 4px 6px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  background: var(--Success-100, #e0f0d7);
  position: absolute;
  left: 12px;
  top: -10px;
}

.borrow-popup-content-body-main-label-text {
  color: var(--Success-600, #41732f);
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 0.875rem */
}

.borrow-popup-repay-content-body-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.borrow-popup-repay-content-body-text {
  color: #000;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.4rem */
}

.verify-kyc-button-inactive {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: var(--space-8px, 8px);
  flex: 1 0 0;
  border-radius: var(--space-8px, 8px);
  opacity: 0.5;
  background: var(--primary-bitter-lemon-500, #cfe600);
  cursor: pointer;
  user-select: none;
}

.convert-funds-popup-input-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 45px;
  align-self: stretch;
  position: relative;
}

.convert-funds-popup-first-input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.convert-funds-popup-first-input-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.convert-funds-popup-first-input-container {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
}

.convert-funds-popup-first-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  background: transparent;
  width: 100%;
  color: var(--Neutral-950, #0c0c0e);

  /* Heading/H4 */
  font-family: Manrope;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.5rem */
}

.convert-funds-popup-first-input::placeholder {
  color: var(--Neutral-300, #b6b7c3);

  /* Heading/H4 */
  font-family: Manrope;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.convert-funds-popup-first-input-content {
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  cursor: pointer;
}

.convert-funds-popup-first-input-content-icon {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}

.convert-funds-popup-first-input-content-text-container {
  display: flex;
  align-items: center;
  gap: 2px;
}

.convert-funds-popup-first-input-content-text {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.convert-funds-popup-input-group-icon {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 104px;
  border-radius: 24px;
  background: var(--Neutral-50, #f7f7f8);
  overflow: hidden;
  cursor: pointer;
}

.convert-funds-popup-second-input-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin-top: 25px;
}

.convert-funds-popup-first-line {
  width: 100%;
  height: 1px;
  background: #eeeef0;
}

.convert-funds-popup-second-input-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
  align-self: stretch;
}

.convert-funds-popup-second-input-footer-content {
  display: flex;
  align-items: center;
  gap: var(--space-8px, 8px);
}

.convert-funds-popup-second-input-footer-content-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.convert-funds-popup-second-input-footer-content-title {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.convert-funds-popup-second-input-footer-content-label {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
}

.convert-funds-popup-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  overflow-x: hidden;
  overflow-y: auto;
}

.convert-funds-popup-body-first {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--secondary-electric-violet-50, #ecefff);
  cursor: pointer;
}

.convert-funds-popup-body-first-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.convert-funds-popup-body-first-header-icon {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: var(--secondary-electric-violet-100, #dde1ff);
  overflow: hidden;
}

.convert-funds-popup-body-first-header-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.convert-funds-popup-body-first-header-text-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.convert-funds-popup-body-first-header-text {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
}

.convert-funds-popup-body-first-header-label {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.convert-funds-popup-body-second {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  background: var(--Neutral-50, #f7f7f8);
}

.convert-funds-popup-body-second-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.convert-funds-popup-body-second-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.convert-funds-popup-body-second-title {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P3 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
}

.convert-funds-popup-body-second-label {
  color: var(--Neutral-600, #5b5d6e);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.convert-funds-popup-body-second-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-8px, 8px);
}

.convert-funds-popup-body-second-footer-content {
  display: flex;
  align-items: center;
  gap: 6px;
}

.convert-funds-popup-body-second-footer-content-text {
  color: var(--Neutral-800, #40414c);
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 0.875rem */
}

.convert-funds-popup-graph {
  display: flex;
  padding: 40px 8px 18px 7px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: var(--space-8px, 8px) var(--space-8px, 8px) 0px 0px;
  background: var(--common-white_states-main, #fff);
  width: 100%;
}

.convert-funds-popup-first-input-error-container {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.convert-funds-popup-first-input-error-text {
  color: var(--Error-600, #e23636);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.convert-funds-popup-first-input-main {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.convert-funds-popup-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.convert-funds-popup-header-text {
  color: var(--secondary-electric-violet-600, #603ff4);

  /* Paragraph 03/P3 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
  cursor: pointer;
  user-select: none;
}

.convert-funds-popup-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.select-bank-popup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.select-bank-popup-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.select-bank-popup-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.select-bank-popup-body-container {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.select-bank-popup-body-content-container {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.select-bank-popup-body-content-icon {
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.select-bank-popup-body-content-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}

.select-bank-popup-body-content-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
}

.select-bank-popup-body-content-text {
  color: #000;

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.select-bank-popup-body-content-label {
  color: var(--Neutral-500, #717286);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.select-bank-popup-body-content {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  cursor: pointer;
}

.select-bank-withdraw-header {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.select-bank-withdraw-header-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.select-bank-withdraw-header-text {
  color: var(--Neutral-700, #4a4a5a);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.select-bank-withdraw-header-label {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 02/P1 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  align-self: stretch;
}

.watchlist-page-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.watchlist-page-content-first {
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
}

.watchlist-page-content-first-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.watchlist-page-content-first-header-container {
  display: flex;
  padding: 4px var(--space-8px, 8px);
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Success-50, #f1f8ed);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.watchlist-page-content-first-header-text {
  color: var(--Success-700, #38602b);
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 0.625rem */
}

.watchlist-page-content-first-header-text-red {
  color: var(--Error-700, #bc1919);
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 0.625rem */
}

.watchlist-page-content-first-header-icon {
  display: flex;
  padding: var(--space-0, 0px) 6px;
  align-items: center;
  gap: 100px;
}

.watchlist-page-content-first-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.watchlist-page-content-first-body-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.watchlist-page-content-first-body-title {
  color: var(--Neutral-950, #0c0c0e);

  /* Paragraph 02/P2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.watchlist-page-content-first-body-text-label {
  display: flex;
  width: 450px;
  justify-content: space-between;
  align-items: center;
}

.watchlist-page-content-first-body-text-container {
  display: flex;
  width: 200px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}

.watchlist-page-content-first-body-text-body {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: var(--secondary-electric-violet-100, #dde1ff);
}

.asset-info-popup-highchart-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
}

.watchlist-page-content-first-body-text {
  color: var(--secondary-electric-violet-900, #362689);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
}

.watchlist-page-content-first-body-label-body {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: var(--Neutral-100, #eeeef0);
}

.watchlist-page-content-first-body-label {
  color: var(--Neutral-800, #40414c);

  /* Paragraph 04/P1 */
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.875rem */
}

.watchlist-page-content-first-footer-container {
  display: flex;
  align-items: center;
  gap: 100px;
}

.watchlist-page-content-first-footer-text-container {
  display: flex;
  align-items: center;
  gap: 40px;
}

.watchlist-page-content-first-footer-text {
  color: var(--Neutral-400, #8f91a1);

  /* Paragraph 03/P1 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.watchlist-page-content-first-footer-label-container {
  display: flex;
  align-items: center;
}

.watchlist-page-content-first-footer-label {
  color: var(--Success-700, #38602b);
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
}

.watchlist-page-content-first-label {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  background: var(--Accent-100, #fff9c1);
}

.watchlist-page-content-first-label-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.watchlist-page-content-first-label-text-container {
  display: flex;
  padding: var(--space-0, 0px) 1px;
  align-items: center;
  gap: 4px;
}

.watchlist-page-content-first-label-text-main {
  color: var(--Accent-800, #89490a);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 0.75rem */
}

.watchlist-page-content-first-label-text {
  color: var(--Accent-800, #89490a);

  /* Paragraph 03/P2 */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  align-self: stretch;
}

.watchlist-page-content-second {
  display: flex;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--space-8px, 8px);
  border: 1px solid var(--Neutral-100, #eeeef0);
  background: var(--common-white_states-main, #fff);
}

.watchlist-page-content-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
}

.assets-overlay-text-title {
  color: var(--Neutral-950, #0c0c0e);
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.02rem;
  min-height: 48px;
}

@media screen and (min-width: 300px) and (max-width: 1200px) {
  .dashboard-overlay-container {
    width: 93.05556%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    margin-right: 1.38888%;
    margin-left: 5.5556%;
  }

  .dashboard-overlay-first-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--space-8px, 8px);
  }

  .dashboard-overlay {
    display: flex;
    width: 100%;
    padding: 10px 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--space-8px, 8px);
    background: var(--secondary-electric-violet-50, #ecefff);
  }

  .dashboard-overlay-second-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
    margin-bottom: 20px;
  }

  .overlay-slider {
    display: flex;
    width: 42px;
    height: 6px;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    border-radius: var(--space-8px, 8px);
  }

  .overlay-slider-dot-active {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    border-radius: var(--space-8px, 8px);
    background: var(--primary-bitter-lemon-500, #cfe600);
  }

  .overlay-slider-dot {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    border-radius: var(--space-8px, 8px);
    background: var(--secondary-electric-violet-950, #21174f);
  }

  .overlay-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .overlay-text {
    color: var(--Neutral-950, #0c0c0e);

    /* Caption/C1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 1.125rem */
    width: 100%;
  }

  .overlay-button {
    display: flex;
    width: "fit-content";
    max-height: 57.142857%;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--secondary-electric-violet-600, #603ff4);
  }

  .overlay-button-text {
    color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
    text-align: center;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    /* 142.857% */
  }

  .overlay-header-text {
    color: var(--Neutral-White-100, var(--common-white_states-main, #fff));

    /* Sub Heading/SH2 */
    font-family: Manrope;
    font-size: 1rem;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    /* 1rem */
    align-self: stretch;
  }

  .overlay-custom-slider-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
  }

  .overlay-custom-slider {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
  }

  .overlay-slider-line {
    width: 21.791%;
    height: 4px;
    border-radius: 12px;
    background: var(--secondary-electric-violet-900, #362689);
  }

  .overlay-slider-line-active {
    width: 21.791%;
    height: 4px;
    border-radius: 12px;
    background: var(--primary-bitter-lemon-500, #cfe600);
  }

  .overlay-slider-line-text {
    color: var(--Neutral-White-100, var(--common-white_states-main, #fff));
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    /* 1.09375rem */
    width: 6.86567%;
  }

  .dashboard-overlay-second-section-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: var(--space-8px, 8px);
    background: linear-gradient(75deg, #f9ffc4 0%, #efff59 99.4%);
    padding: 15px 0 15px 15px;
  }

  .dashboard-overlay-second-section-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .d-second-section-content-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }

  .d-second-section-header-text {
    width: 100%;
  }

  .d-second-section-fotter-text {
    color: var(--Neutral-700, #4a4a5a);

    /* Paragraph 03/P1 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 1.05rem */
    width: 100%;
  }

  .d-second-section-content-image {
    height: 100%;

    width: 45.373%;
    right: 0px;
  }

  .mobile-layout-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
    padding: 12px;
  }

  .mobile-layout-first-section {
    display: flex;
    /* height: 397px; */

    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
  }

  .mobile-layout-second-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
  }

  .m-layout-first-section-header {
    display: flex;
    width: 335px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .m-layout-first-section-text1 {
    color: var(--Neutral-950, #0c0c0e);
    text-align: center;

    /* Sub Heading/SH2 */
    font-family: Manrope;
    font-size: 1rem;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 1rem */
  }

  .m-layout-first-section-text2 {
    color: var(--secondary-electric-violet-700, #4e2bd7);
    text-align: center;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 1.09375rem */
    cursor: pointer;
    user-select: none;
  }

  .m-layout-second-section-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
  }

  .m-layout-second-section-content {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    border-radius: 12px;
    background: linear-gradient(81deg, #eaefff 0%, #bcf 100%);
  }

  .m-layout-second-section-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    width: 47.76119%;
  }

  .m-layout-second-section-text {
    color: var(--Neutral-950, #0c0c0e);

    /* Paragraph 01/P3 */
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.5rem */
    width: 100%;
  }

  .m-layout-second-section-image {
    width: 140px;
    /* height: 140px; */
  }

  .mobille-startup-search-box-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
  }

  .mobile-startup-search-box {
    display: flex;
    height: 48px;
    padding: 12px;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    border: 1px solid var(--Neutral-200, #d8d9df);
    background: var(--Neutral-50, #f7f7f8);
    width: 100%;
    overflow: hidden;
  }

  .ms-search-box-content {
    height: 100%;
    width: 100%;
    background: transparent;
    color: var(--Neutral-950, #0c0c0e);

    /* Paragraph 02/P1 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.3125rem */
  }

  .ms-search-box-content::placeholder {
    color: var(--Neutral-400, #8f91a1);

    /* Paragraph 02/P1 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.3125rem */
  }

  .mobile-startup-search-content {
    display: flex;
    width: 100%;
    padding: var(--space-8px, 8px);
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: var(--space-8px, 8px);
    border: 1px solid var(--Neutral-50, #f7f7f8);
    background: var(--common-white_states-main, #fff);
  }

  .ms-search-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    overflow-x: hidden;
    overflow-y: auto;
  }

  .ms-search-content {
    display: flex;
    padding: var(--space-8px, 8px);
    align-items: center;
    gap: 19px;
    align-self: stretch;
    cursor: pointer;
    user-select: none;
  }

  .ms-search-content-active {
    display: flex;
    padding: var(--space-8px, 8px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    background: var(--Success-50, #f1f8ed);
    cursor: pointer;
    user-select: none;
  }

  .ms-search-content-text-container {
    display: flex;
    align-items: center;
    gap: 19px;
  }

  .ms-search-content-icon {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: var(--common-white_states-main, #fff);
    box-shadow: 0px 1px 4px 0px #eee;
  }

  .ms-search-content-text {
    color: var(--Neutral-950, #0c0c0e);

    /* Paragraph 02/P1 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.3125rem */
  }

  .startup-slider {
    display: flex;
    width: 335px;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    width: 100%;
  }

  .startup-slider-line {
    height: 4px;
    flex-shrink: 0;
    border-radius: var(--space-8px, 8px);
    background: var(--Neutral-100, #eeeef0);
  }

  .startup-slider-line-active {
    height: 4px;
    flex: 1 0 0;
    border-radius: var(--space-8px, 8px);
    background: var(--secondary-electric-violet-600, #603ff4);
  }
}

/* Target Lanscape??? */
@media screen and (min-width: 410px) and (max-width: 1200px) {
  .m-layout-second-section-content {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    border-radius: 12px;
    background: linear-gradient(81deg, #eaefff 0%, #bcf 100%);
    align-items: center;
    justify-content: center;
  }
}

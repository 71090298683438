.header {
    display: flex;
    width: 100%;
    height: 64px;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    background: var(--secondary-electric-violet-800, #3f25ae);
    position: sticky;
    top: 0;
    z-index: 4;
}

.header-logo {
    width: 73px;
    height: 20px;
    flex-shrink: 0;
    cursor: pointer;
    user-select: none;
}

.header-logo:hover {
    background-color: transparent;
}

.header-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--space-8px, 8px);
}

.header-icon {
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    cursor: pointer;
    user-select: none;
    background: var(--secondary-electric-violet-900, #362689);
}

.header-icon-clicked {
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    cursor: pointer;
    user-select: none;
    background-color: var(--secondary-electric-violet-950, #21174F);
}

.header-icon-clicked:hover{
    background-color: var(--secondary-electric-violet-950, #21174F);
}

.header-icon:hover {
    background-color: var(--secondary-electric-violet-400, #7A76FF);
}


.header-icon-overlay-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    position: relative;
}

.header-icon-overlay-body{
    display: flex;
width: 375px;
height: 680px;
padding: 24px var(--space-0, 0px);
flex-direction: column;
align-items: flex-start;
gap: var(--space-8px, 8px);
flex-shrink: 0;
border-radius: var(--space-8px, 8px);
background: var(--common-white_states-main, #FFF);
box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.12);
z-index: 4;
position: absolute;
right: 0;
top: 45px;
padding-bottom: 0;
}

.header-icon-overlay-body-header{
    display: flex;
padding: var(--space-0, 0px) 20px;
justify-content: space-between;
align-items: center;
align-self: stretch;
}

.header-icon-overlay-body-header-first{
    display: flex;
align-items: center;
gap: var(--space-8px, 8px);
}

.header-icon-overlay-body-header-first-container{
    display: flex;
align-items: center;
gap: 2px;
}

.header-icon-overlay-body-header-first-icon{
    display: flex;
padding: 6px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 100px;
}

.header-icon-overlay-body-header-second{
    display: flex;
justify-content: center;
align-items: center;
gap: 4px;
}

.header-icon-overlay-body-header-second-icon{
    display: flex;
padding: 6px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 100px;
}

.header-icon-overlay-body-footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: stretch; 
    overflow-x: hidden;
    overflow-y: auto;
}

.header-icon-overlay-body-footer-first{
    display: flex;
width: 100%;
padding: 24px;
align-items: flex-start;
gap: 12px;
background: var(--common-white_states-main, #FFF);
}

.header-icon-overlay-body-footer-first-icon{
    display: flex;
width: 48px;
padding: 13px 8px 9px 9px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 200px;
background: var(--Neutral-50, #F7F7F8);
}

.header-icon-overlay-body-footer-first-body{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px; 
}

.header-icon-overlay-body-footer-first-body-text-container{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: var(--space-8px, 8px);
}

.header-icon-overlay-body-footer-first-body-text{
    color: var(--Neutral-500, #717286);

    /* Paragraph 02/P2 */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.225rem */  
}

.header-icon-overlay-body-footer-first-body-label{
    width: 251px;
    color: var(--Neutral-500, #717286);

/* Paragraph 02/P1 */
font-family: Roboto;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 1.3125rem */
}

.header-icon-overlay-body-footer-first-body-custom-label{
    color: var(--Neutral-950, #0C0C0E);

/* Paragraph 02/P2 */
font-family: Roboto;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: 140%;
}

.header-icon-overlay-body-footer-first-body-content{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
align-self: stretch;
}

.header-icon-overlay-body-footer-first-body-button-active{
    display: flex;
padding: 9.5px 16px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: var(--space-8px, 8px);
border: 1px solid var(--secondary-electric-violet-600, #603FF4);
background: var(--secondary-electric-violet-600, #603FF4);
}

.header-icon-overlay-body-footer-first-body-button-text-active{
    color: var(--secondary-electric-violet-50, #ECEFFF);
text-align: right;

/* Paragraph 03/P2 */
font-family: Roboto;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 1.05rem */
}

.header-icon-overlay-body-footer-first-body-button{
    display: flex;
padding: 9.5px 16px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: var(--space-8px, 8px);
border: 1px solid var(--secondary-electric-violet-600, #603FF4);
}

.header-icon-overlay-body-footer-first-body-button-text{
    color: var(--secondary-electric-violet-600, #603FF4);
    text-align: right;
    
    /* Paragraph 03/P2 */
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.05rem */  
}

.header-icon-overlay-body-footer-first-body-footer-text{
    color: var(--Neutral-300, #B6B7C3);

/* Paragraph 04/P2 */
font-family: Roboto;
font-size: 0.625rem;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 0.875rem */
}

.header-icon-overlay-body-footer-second{
    display: flex;
padding: 24px;
align-items: flex-start;
gap: 12px;
align-self: stretch;
background: var(--common-white_states-main, #FFF);
}

.header-icon-overlay-body-footer-second-body-text{
    color: var(--Neutral-700, #4A4A5A);

/* Paragraph 02/P2 */
font-family: Roboto;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 1.225rem */
}

.header-icon-overlay-body-footer-third{
    display: flex;
width: 100%;
padding: 24px;
align-items: flex-start;
gap: 12px;
background: var(--Neutral-100, #EEEEF0);
}

.header-icon-overlay-body-footer-first-custom-icon{
    display: flex;
padding: 9px 10px;
justify-content: center;
align-items: center;
border-radius: 200px;
background: var(--Neutral-50, #F7F7F8);
}

.header-icon-overlay-body-footer-third-body-text{
    color: var(--Neutral-500, #717286);

/* Paragraph 02/P2 */
font-family: Roboto;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 1.225rem */
}

.header-icon-overlay-body-footer-third-body-custom-label{
    color: var(--Neutral-950, #0C0C0E);

/* Paragraph 02/P2 */
font-family: Roboto;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 1.225rem */
}



@media screen and (min-width:  320px)  and (max-width: 1200px) {
    .header {
        display: flex;
        width: 100%;
        padding-left: 5.5556%;
        padding-right: 1.388880%;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5.5556%;
        padding-top: 5.5556%;
    }
}

.mobile-header-first-section{
    display: flex;
align-items: center;
gap: 12px;
}

.mobile-header-first-section-text{
    color: var(--Neutral-White-100, var(--common-white_states-main, #FFF));

/* Heading/H4 */
font-family: Manrope;
font-size: 1.25rem;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 1.5rem */
}
